// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Loader from 'components/fragments/Loader';
import { LOGIN_PATH } from 'constants/routes';
import STATUS from 'constants/status';

import type { StateType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';

type StateProps = {
  isLogged: boolean,
  status: StatusType,
};

const mapStateToProps = (state: StateType): StateProps => ({
  isLogged: state.userState.isLogged,
  status: state.userState.status,
});

const connector = connect(mapStateToProps);

const onlyLogged = (Component: any) => connector(
  ({ isLogged, status }: StateProps) => {
    if (status === STATUS.DEFAULT || status === STATUS.LOADING) {
      return <Loader center />;
    }
    return isLogged ? <Component /> : <Redirect push to={LOGIN_PATH} />;
  },
);

export default onlyLogged;
