// @flow

import React, { useState, useCallback } from 'react';
import RoundedArrowIcon from 'assets/RoundedArrowIcon';
import { CLASSNAME, COLORS } from 'constants/styles';
import computeStyles from 'utils/styles';

import styles from 'styles/collapsable.module.css';

type Props = {
  previewHeight: number,
  children: React$Element<any>,
};

const Collapsable = ({
  previewHeight,
  children,
}: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  const onToggle = useCallback(() => {
    setIsOpened((value) => !value);
  }, [setIsOpened]);

  return (
    <>
      <div className={styles.main}>
        <div
          style={{ height: !isOpened ? previewHeight : '100%' }}
          className={computeStyles(styles.container, CLASSNAME.onlyMobile)}>
          {children}
        </div>
        <button
          className={computeStyles(styles.button, isOpened && styles.button_opened, CLASSNAME.onlyMobile)}
          onClick={onToggle}>
          <RoundedArrowIcon fill={COLORS.fill} />
        </button>
      </div>
      <div className={computeStyles(styles.main, styles.desktop_container, CLASSNAME.onlyDesktop)}>
        {children}
      </div>
    </>
  );
};

export default React.memo<Props>(Collapsable);
