// @flow

import { all, spawn } from 'redux-saga/effects';

import { type Saga } from 'redux-saga';
import registerSaga from 'sagas/registerSaga';
import authSaga from 'sagas/authSaga';
import sessionSaga from 'sagas/sessionSaga';
import paymentSaga from 'sagas/paymentSaga';
import fidelityCardSaga from 'sagas/fidelityCardSaga';
import organizationSaga from 'sagas/organizationSaga';

import apiInstance from 'infrastructure/http/index';

import { baseUrl } from 'constants/api';

const api = apiInstance(baseUrl);

api.setApiKey(process.env.REACT_APP_API_KEY);

function* root(): Saga<void> {
  yield all([
    spawn(authSaga(api)),
    spawn(registerSaga(api)),
    spawn(sessionSaga(api)),
    spawn(paymentSaga(api)),
    spawn(fidelityCardSaga(api)),
    spawn(organizationSaga(api)),
  ]);
}

export default root;
