// @flow

import { combineReducers } from 'redux';

import appState, { type StateType as AppStateType } from 'reducers/app';
import userState, {
  type StateType as UserStateType,
} from 'reducers/userReducer';
import modalState, {
  type StateType as ModalStateType,
} from 'reducers/modalReducer';
import snackBarState, {
  type StateType as SnackBarStateType,
} from 'reducers/snackBarReducer';
import sessionState, {
  type StateType as SessionStateType,
} from 'reducers/sessionReducer';
import paymentState, {
  type StateType as PaymentStateType,
} from 'reducers/paymentReducer';
import fidelityCardState, {
  type StateType as fidelityCardStateType,
} from 'reducers/fidelityCardReducer';
import organizationState, {
  type StateType as organizationStateType,
} from 'reducers/organizationReducer';
import localeState, {
  type StateType as localeStateType,
} from 'reducers/localeReducer';


type StateType = {
  appState: AppStateType,
  userState: UserStateType,
  modalState: ModalStateType,
  snackBarState: SnackBarStateType,
  sessionState: SessionStateType,
  paymentState: PaymentStateType,
  fidelityCardState: fidelityCardStateType,
  organizationState: organizationStateType,
  localeState: localeStateType,
};

const rootReducer = combineReducers<StateType>({
  appState,
  userState,
  modalState,
  snackBarState,
  sessionState,
  paymentState,
  fidelityCardState,
  organizationState,
  localeState,
});

export default rootReducer;
