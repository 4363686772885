// @flow

import { SHOW_MODAL, HIDE_MODAL } from 'actions/modalActions';

import type { ActionType } from 'types/actionsType';

export type StateType = {
  isOpened: boolean,
  title: string,
  children: React$Element<any> | null,
};

const initialState: StateType = {
  isOpened: false,
  title: '',
  children: null,
};

export default function modalState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        isOpened: true,
        children: action.payload.children,
        title: action.payload.title,
      };
    case HIDE_MODAL: {
      return {
        ...state,
        isOpened: false,
      };
    }
    default:
      return state;
  }
}
