// @flow

import type { AuthDataType } from 'types/authType';
import type { RegisterDataType } from 'types/registerType';

type UserAuthType = {
  email: string,
  token: string,
  firstName: string,
  lastName: string,
};

type UserRegisterType = {
  id: string,
  partnerId: string,
  partnerName: string,
  token: string,
};

export const AUTH = 'AUTH';
export type AUTH_ACTION = {
  type: typeof AUTH,
  payload: AuthDataType,
};
export const auth = (authData: AuthDataType): AUTH_ACTION => ({
  type: AUTH,
  payload: authData,
});

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export type AUTH_SUCCESS_ACTION = {
  type: typeof AUTH_SUCCESS,
  payload: UserAuthType,
};
export const authSuccess = (userAuth: UserAuthType): AUTH_SUCCESS_ACTION => ({
  type: AUTH_SUCCESS,
  payload: userAuth,
});

export const AUTH_FAILURE = 'AUTH_FAILURE';
export type AUTH_FAILURE_ACTION = {
  type: typeof AUTH_FAILURE,
};
export const authFailure = (): AUTH_FAILURE_ACTION => ({
  type: AUTH_FAILURE,
});

export const AUTH_FROM_CACHE = 'AUTH_FROM_CACHE';
export type AUTH_FROM_CACHE_ACTION = {
  type: typeof AUTH_FROM_CACHE,
  payload: { token: ?string, email: ?string },
};
export const authFromCache = (token: ?string, email: ?string): AUTH_FROM_CACHE_ACTION => ({
  type: AUTH_FROM_CACHE,
  payload: { token, email },
});

export const AUTH_SUCCESS_FROM_CACHE = 'AUTH_SUCCESS_FROM_CACHE';
export type AUTH_SUCCESS_FROM_CACHE_ACTION = {
  type: typeof AUTH_SUCCESS_FROM_CACHE,
  payload: { token: string, email?: string, firstName?: string, lastName?: string },
};
export const authSuccessFromCache = (
  token: string,
  email?: string,
  firstName?: string,
  lastName?: string,
): AUTH_SUCCESS_FROM_CACHE_ACTION => ({
  type: AUTH_SUCCESS_FROM_CACHE,
  payload: {
    token, email, firstName, lastName,
  },
});

export const AUTH_FAILURE_FROM_CACHE = 'AUTH_FAILURE_FROM_CACHE';
export type AUTH_FAILURE_FROM_CACHE_ACTION = {
  type: typeof AUTH_FAILURE_FROM_CACHE,
};
export const authFailureFromCache = (): AUTH_FAILURE_FROM_CACHE_ACTION => ({
  type: AUTH_FAILURE_FROM_CACHE,
});


export const REGISTER = 'REGISTER';
export type REGISTER_ACTION = {
  type: typeof REGISTER,
  payload: RegisterDataType,
};
export const register = (registerData: RegisterDataType): REGISTER_ACTION => ({
  type: REGISTER,
  payload: registerData,
});

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export type REGISTER_SUCCESS_ACTION = {
  type: typeof REGISTER_SUCCESS,
  payload: UserRegisterType & {
    firstName: string,
    lastName: string,
  },
};
export const registerSuccess = (
  userRegister: UserRegisterType,
  firstName: string,
  lastName: string,
): REGISTER_SUCCESS_ACTION => ({
  type: REGISTER_SUCCESS,
  payload: {
    ...userRegister,
    firstName,
    lastName,
  },
});

export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export type REGISTER_FAILURE_ACTION = {
  type: typeof REGISTER_FAILURE,
};
export const registerFailure = (): REGISTER_FAILURE_ACTION => ({
  type: REGISTER_FAILURE,
});

export const LOGOUT = 'LOGOUT';
export type LOGOUT_ACTION = {
  type: typeof LOGOUT,
};
export const logout = (): LOGOUT_ACTION => ({
  type: LOGOUT,
});

export type Action =
  | AUTH_ACTION
  | AUTH_SUCCESS_ACTION
  | AUTH_FAILURE_ACTION
  | AUTH_SUCCESS_FROM_CACHE_ACTION
  | REGISTER_ACTION
  | REGISTER_SUCCESS_ACTION
  | REGISTER_FAILURE_ACTION
  | LOGOUT_ACTION;
