// @flow

import React from 'react';

import styles from 'styles/error.module.css';

type Props = {
  msg: string,
};

const Error = ({ msg }: Props) => (
  <div className={styles.main}>
    <p className={styles.message}>{msg}</p>
  </div>
);

export default React.memo<Props>(Error);
