// @flow
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { history } from 'browser';
import { store, persistor } from 'store';

import TranslationProvider from 'components/translationProvider/TranslationProvider';
import App from 'components/app/App';

const AppProvider = () => (
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <Router history={history}>
        <TranslationProvider>
          <App />
        </TranslationProvider>
      </Router>
    </Provider>
  </PersistGate>
);

export default AppProvider;
