// @flow

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

function organizationApi(executor: ApiExecutorType) {
  function list() {
    return executor.get('/organization');
  }

  return { list };
}

export default organizationApi;
