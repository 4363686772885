// @flow

import { type ActionType } from 'types/redux';
import { INIT } from 'actions/app';

export type StateType = {};

const initialState: StateType = {};

const appState = (state: StateType = initialState, action: ActionType): StateType => {
  switch (action.type) {
    case INIT:
      return state;
    default:
      return state;
  }
};

export default appState;
