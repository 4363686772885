// @flow

import React from 'react';

import computeStyles from 'utils/styles';

import styles from 'styles/loader.module.css';

type Props = {
  center?: boolean,
  primaryColor?: boolean,
};

const Loader = ({ center, primaryColor }: Props) => {
  const container = computeStyles(styles.main, center && styles.main_center);

  const bubble = computeStyles(styles.bubble, {
    [styles.primary]: primaryColor,
  });

  const firstBubble = computeStyles(bubble, { [styles.firstBubble]: 'firstBubble' });
  const secondBubble = computeStyles(bubble, { [styles.secondBubble]: 'secondBubble' });
  const thirdBubble = computeStyles(bubble, { [styles.thirdBubble]: 'thirdBubble' });
  const fourthBubble = computeStyles(bubble, { [styles.fourthBubble]: 'fourthBubble' });

  return (
    <div className={styles.wrapper}>
      <div className={container}>
        <div className={firstBubble} />
        <div className={secondBubble} />
        <div className={thirdBubble} />
        <div className={fourthBubble} />
      </div>
    </div>
  );
};

Loader.defaultProps = {
  center: false,
  primaryColor: false,
};

export default Loader;
