// @flow
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';

import Button from 'components/fragments/Button';
import Input from 'components/fragments/Input';
import { useTranslation } from 'components/translationProvider/TranslationProvider';

import type { SessionType } from 'types/sessionType';
import type { FidelityFeedbackType } from 'types/fidelityCardType';
import styles from 'styles/session.module.css';


export type DispatchProps = {
  addFidelityCard: Function,
  deleteCardNumber: Function,
};

export type StateProps = {
  message: FidelityFeedbackType | null,
  session: SessionType | null,
};

type Props = {
  playerIndex: number,
  connectedUserCard?: string,
} & DispatchProps & StateProps;

const AddFidelityCard = ({
  addFidelityCard,
  deleteCardNumber,
  message,
  playerIndex,
  session,
  connectedUserCard,
}: Props) => {
  const { players = [] } = session || {};

  const [cardNumber, setCardNumber] = useState<string>('');
  const [{ t }] = useTranslation();

  useEffect(() => {
    if (players.length > playerIndex) {
      setCardNumber(String(players[playerIndex].cardNumber));
    }
  }, [playerIndex, players]);

  const hasNoCardNumber = useMemo(() => (
    players.length < playerIndex
    || !players[playerIndex].cardNumber
  ), [players, playerIndex]);

  const handleSubmit = useCallback(() => addFidelityCard({
    cardNumber,
    playerIndex,
  }), [addFidelityCard, cardNumber, playerIndex]);

  const handleDelete = () => {
    setCardNumber('');
    deleteCardNumber(playerIndex);
  };

  const onChange = useCallback((value) => setCardNumber(value), [setCardNumber]);
  // @TODO FORM INTEGRATION
  return (
    <div className={styles.add_card}>
      <p className={styles.add_card_title}>
        {t('session.create.add_card_title')}
      </p>
      <Input
        placeholder={t('fidelity.input_label')}
        onChange={onChange}
        type={'text'}
        label={t('fidelity.input_label')}
        name={'cardNumber'}
        initialValue={cardNumber} />
      {!!(message && message.playerIndex === playerIndex) && (
        <p className={message.error ? styles.add_card__error : styles.add_card__success}>
          {t(message.content)}
        </p>
      )}
      <div className={!hasNoCardNumber ? styles.add_card_submit : styles.add_card_submit_right}>
        {hasNoCardNumber ? (
          <Button
            isDisabled={cardNumber === connectedUserCard}
            buttonType={'tertiary'}
            label={t('fidelity.add_card_label')}
            onClick={handleSubmit} />
        )
          : (
            <Button
              buttonType={'tertiary'}
              label={t('fidelity.delete_card_label')}
              onClick={handleDelete} />
          )}
        {cardNumber !== '' && cardNumber === connectedUserCard && (
          <p className={styles.add_card__error}>
            {t('fidelity.used')}
          </p>
        )}
      </div>
    </div>
  );
};

export default AddFidelityCard;
