// @flow

import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

import FidelityCardApi from 'api/fidelityCardApi';

import {
  ADD_FIDELITY_CARD,
  addFidelityCardFailure,
  addFidelityCardSuccess,
  DELETE_CARD_NUMBER,
} from 'actions/fidelityCardActions';
import type {
  ADD_FIDELITY_CARD_ACTION,
  DELETE_CARD_NUMBER_ACTION,
} from 'actions/fidelityCardActions';
import { updateSession } from 'actions/sessionActions';

import FIDELITY from 'constants/fidelityCard';

export default function (apiExecutor: ApiExecutorType) {
  const fidelityCardApi = new FidelityCardApi(apiExecutor);

  function* addFidelityCard(action: ADD_FIDELITY_CARD_ACTION) {
    try {
      const { payload: { fidelityCard } } = action;
      const { success, data: { status } } = yield call(fidelityCardApi.addFidelityCard, fidelityCard);

      const { playerIndex, cardNumber } = fidelityCard;

      if (success && status === FIDELITY.ENABLED) {
        yield put(addFidelityCardSuccess({ content: 'fidelity.success', error: false, playerIndex }));

        const players = yield select((state) => state.sessionState.session && state.sessionState.session.players);

        if (Array.isArray(players) && players.length > playerIndex) {
          players[playerIndex] = { ...players[playerIndex], cardNumber };
        }

        yield put(updateSession({ players }));
      }

      if (success && status === FIDELITY.EXPIRED) {
        yield put(addFidelityCardFailure({ content: 'fidelity.expired', error: true, playerIndex }));
      }

      if (!success && status === FIDELITY.NOT_FOUND) {
        yield put(addFidelityCardFailure({ content: 'fidelity.not_found', error: true, playerIndex }));
      }
    } catch {
      const { payload: { fidelityCard: { playerIndex } } } = action;

      yield put(addFidelityCardFailure({ content: 'fidelity.not_found', error: true, playerIndex }));
    }
  }

  function* deleteCardNumber(action: DELETE_CARD_NUMBER_ACTION) {
    const { payload: { playerIndex } } = action;

    const players = yield select((state) => state.sessionState.session && state.sessionState.session.players);

    if (Array.isArray(players) && players.length > playerIndex) {
      players[playerIndex] = {
        ...players[playerIndex],
        cardNumber: '',
        fidelityProgram: '',
        partnerFee: null,
      };
    }

    yield put(updateSession({ players }));
  }

  return function* fidelityCardSaga(): Saga<*> {
    yield takeLatest(ADD_FIDELITY_CARD, addFidelityCard);
    yield takeLatest(DELETE_CARD_NUMBER, deleteCardNumber);
  };
}
