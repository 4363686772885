// @flow

import { connect } from 'react-redux';
import { hideModal } from 'actions/modalActions';
import Modal, {
  type StateProps,
  type DispatchProps,
} from 'components/feedback/Modal';

import type { StateType } from 'types/actionsType';

const mapStateToProps = (state: StateType): StateProps => ({
  isOpened: state.modalState.isOpened,
  title: state.modalState.title,
  children: state.modalState.children,
});

const mapDispatchToProps: DispatchProps = {
  hideModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
