// @flow

import type { StateType } from 'types/actionsType';

export const selectUserInformation = (state: StateType) => ({
  firstName: state.userState.firstName,
  lastName: state.userState.lastName,
  email: state.userState.email,
});

export const selectToken = (state: StateType) => state.userState.token;
