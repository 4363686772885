// @flow

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';
import type{ StripeSessionType } from 'types/paymentType';

function PaymentApi(executor: ApiExecutorType) {
  function createStripeSession(sessionData: StripeSessionType) {
    return executor.post('/stripe', sessionData);
  }

  return {
    createStripeSession,
  };
}

export default PaymentApi;
