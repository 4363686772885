// @flow
import React, { type Node as ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';

type Props = {
  children: ReactNode;
  text: string;
  name: string;
}

const ToolTip = ({ children, text, name }: Props) => (
  <>
    <div data-tip data-for={name}>
      {children}
    </div>
    <ReactTooltip id={name} type={'dark'}>
      {text}
    </ReactTooltip>
  </>
);

ToolTip.defaultProps = {
  text: '',
};

export default ToolTip;
