// @flow

import type {
  SessionDataType, SessionType, UpdateSessionDataType, SessionPlayerType,
} from 'types/sessionType';

export const EXTRACT_SESSION_TOKEN = 'EXTRACT_SESSION_TOKEN';
export type EXTRACT_SESSION_TOKEN_ACTION = {
  type: typeof EXTRACT_SESSION_TOKEN,
  payload: SessionDataType,
};
export const extractSessionToken = (token: string): EXTRACT_SESSION_TOKEN_ACTION => ({
  type: EXTRACT_SESSION_TOKEN,
  payload: { token },
});

export const CREATE_SESSION = 'CREATE_SESSION';
export type CREATE_SESSION_ACTION = {
  type: typeof CREATE_SESSION,
};
export const createSession = (): CREATE_SESSION_ACTION => ({
  type: CREATE_SESSION,
});

export const CREATE_SESSION_SUCCESS = 'CREATE_SESSION_SUCCESS';
export type CREATE_SESSION_SUCCESS_ACTION = {
  type: typeof CREATE_SESSION_SUCCESS,
  payload: { session: SessionType },
};
export const createSessionSuccess = (
  session: SessionType,
): CREATE_SESSION_SUCCESS_ACTION => ({
  type: CREATE_SESSION_SUCCESS,
  payload: { session },
});

export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';
export type CREATE_SESSION_FAILURE_ACTION = {
  type: typeof CREATE_SESSION_FAILURE,
};
export const createSessionFailure = (): CREATE_SESSION_FAILURE_ACTION => ({
  type: CREATE_SESSION_FAILURE,
});

export const GET_SESSION = 'GET_SESSION';
export type GET_SESSION_ACTION = {
  type: typeof GET_SESSION,
  payload: { sessionId: string },
};
export const getSession = (sessionId: string): GET_SESSION_ACTION => ({
  type: GET_SESSION,
  payload: { sessionId },
});

export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export type GET_SESSION_SUCCESS_ACTION = {
  type: typeof GET_SESSION_SUCCESS,
  payload: { session: SessionType },
};
export const getSessionSuccess = (
  session: SessionType,
): GET_SESSION_SUCCESS_ACTION => ({
  type: GET_SESSION_SUCCESS,
  payload: { session },
});

export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';
export type GET_SESSION_FAILURE_ACTION = {
  type: typeof GET_SESSION_FAILURE,
};
export const getSessionFailure = (): GET_SESSION_FAILURE_ACTION => ({
  type: GET_SESSION_FAILURE,
});

export const UPDATE_SESSION = 'UPDATE_SESSION';
export type UPDATE_SESSION_ACTION = {
  type: typeof UPDATE_SESSION,
  payload: { session: UpdateSessionDataType },
};
export const updateSession = (session: UpdateSessionDataType): UPDATE_SESSION_ACTION => ({
  type: UPDATE_SESSION,
  payload: { session },
});

export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export type UPDATE_SESSION_SUCCESS_ACTION = {
  type: typeof UPDATE_SESSION_SUCCESS,
  payload: { session: UpdateSessionDataType },
};
export const updateSessionSuccess = (
  session: UpdateSessionDataType,
): UPDATE_SESSION_SUCCESS_ACTION => ({
  type: UPDATE_SESSION_SUCCESS,
  payload: { session },
});

export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';
export type UPDATE_SESSION_FAILURE_ACTION = {
  type: typeof UPDATE_SESSION_FAILURE,
};
export const updateSessionFailure = (): UPDATE_SESSION_FAILURE_ACTION => ({
  type: UPDATE_SESSION_FAILURE,
});

export const UPDATE_SESSION_PLAYER = 'UPDATE_SESSION_PLAYER';
export type UPDATE_SESSION_PLAYER_ACTION = {
  type: typeof UPDATE_SESSION_PLAYER,
  payload: { players: SessionPlayerType[] },
};
export const updateSessionPlayer = (players: SessionPlayerType[]): UPDATE_SESSION_PLAYER_ACTION => ({
  type: UPDATE_SESSION_PLAYER,
  payload: { players },
});

export const UPDATE_SESSION_PLAYER_SUCCESS = 'UPDATE_SESSION_PLAYER_SUCCESS';
export type UPDATE_SESSION_PLAYER_SUCCESS_ACTION = {
  type: typeof UPDATE_SESSION_PLAYER_SUCCESS,
  payload: { session: UpdateSessionDataType },
};
export const updateSessionPlayerSuccess = (
  session: UpdateSessionDataType,
): UPDATE_SESSION_PLAYER_SUCCESS_ACTION => ({
  type: UPDATE_SESSION_PLAYER_SUCCESS,
  payload: { session },
});

export const UPDATE_SESSION_PLAYER_FAILURE = 'UPDATE_SESSION_PLAYER_FAILURE';
export type UPDATE_SESSION_PLAYER_FAILURE_ACTION = {
  type: typeof UPDATE_SESSION_PLAYER_FAILURE,
};
export const updateSessionPlayerFailure = (): UPDATE_SESSION_PLAYER_FAILURE_ACTION => ({
  type: UPDATE_SESSION_PLAYER_FAILURE,
});

export type Action =
  | CREATE_SESSION_ACTION
  | CREATE_SESSION_SUCCESS_ACTION
  | CREATE_SESSION_FAILURE_ACTION
  | UPDATE_SESSION_ACTION
  | UPDATE_SESSION_FAILURE_ACTION
  | UPDATE_SESSION_SUCCESS_ACTION
  | UPDATE_SESSION_PLAYER_ACTION
  | UPDATE_SESSION_PLAYER_FAILURE_ACTION
  | UPDATE_SESSION_PLAYER_SUCCESS_ACTION
  | GET_SESSION_ACTION
  | GET_SESSION_FAILURE_ACTION
  | GET_SESSION_SUCCESS_ACTION
  | EXTRACT_SESSION_TOKEN_ACTION;
