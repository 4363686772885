// @flow

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';
import type{ FidelityCardType } from 'types/fidelityCardType';

function FidelityCardApi(executor: ApiExecutorType) {
  function addFidelityCard(fidelityCard: FidelityCardType) {
    return executor.get(`/user/fidelity?cardNumber=${fidelityCard.cardNumber}`);
  }

  return {
    addFidelityCard,
  };
}

export default FidelityCardApi;
