// @flow
import React from 'react';
import ModalContainer from 'containers/feedback/ModalContainer';
import SnackBarContainer from 'containers/feedback/SnackBarContainer';

import Routes from 'routes';

const App = () => (
  <>
    <ModalContainer />
    <SnackBarContainer />
    <Routes />
  </>
);
export default App;
