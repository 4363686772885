// @flow

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';
import type { AuthDataType } from 'types/authType';
import type { RegisterDataType } from 'types/registerType';

function userApi(executor: ApiExecutorType) {
  function authenticate(body: AuthDataType) {
    return executor.post('/user/authenticate', body);
  }

  function registering(body: RegisterDataType) {
    return executor.post('/user/register', body);
  }

  function getMe() {
    return executor.get('/user/me');
  }

  return {
    authenticate,
    registering,
    getMe,
  };
}

export default userApi;
