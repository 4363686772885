// @flow

import {
  AUTH,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  AUTH_FROM_CACHE,
  AUTH_SUCCESS_FROM_CACHE,
  AUTH_FAILURE_FROM_CACHE,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGOUT,
} from 'actions/userActions';

import STATUS from 'constants/status';

import type { ActionType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';

export type StateType = {
  email: string,
  id: string,
  isLogged: boolean,
  partnerId: string,
  partnerName: string,
  firstName: string,
  lastName: string,
  status: StatusType,
  token: string,
};

const initialState: StateType = {
  email: '',
  id: '',
  isLogged: false,
  partnerId: '',
  partnerName: '',
  firstName: '',
  lastName: '',
  status: STATUS.DEFAULT,
  token: '',
};

export default function userState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case AUTH:
    case AUTH_FROM_CACHE:
    case REGISTER:
      return {
        ...state,
        status: STATUS.LOADING,
      };
    case AUTH_SUCCESS_FROM_CACHE: {
      const {
        payload: {
          token,
          email,
          firstName,
          lastName,
        },
      } = action;

      return {
        ...state,
        isLogged: true,
        status: STATUS.SUCCESS,
        token,
        email: email || state.email,
        firstName: firstName || state.firstName,
        lastName: lastName || state.lastName,
      };
    }
    case AUTH_SUCCESS: {
      const {
        payload: {
          email, token, firstName, lastName,
        },
      } = action;

      return {
        ...state,
        email,
        firstName,
        lastName,
        isLogged: true,
        status: STATUS.SUCCESS,
        token,
      };
    }
    case REGISTER_SUCCESS: {
      const {
        payload: {
          id, partnerId, partnerName, token, firstName, lastName,
        },
      } = action;

      return {
        ...state,
        id,
        partnerId,
        partnerName,
        firstName,
        lastName,
        token,
        isLogged: true,
        status: STATUS.SUCCESS,
      };
    }
    case AUTH_FAILURE_FROM_CACHE:
    case AUTH_FAILURE:
    case REGISTER_FAILURE:
      return {
        ...state,
        isLogged: false,
        status: STATUS.FAILURE,
      };
    case LOGOUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
