// @flow

import React from 'react';

type Props = {
  fill: string,
};
 
const HoleIcon = ({ fill }: Props) => (
  <svg width={'20'} height= {'18'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path 
      d="M13.6372 3.53601L6.96865.0740674c-.10152-.0531131-.21443-.07840039-.328-.07346064C6.52707.00554652 6.4166.0405494 6.31972.102291c-.09689.061742-.17696.148173-.23262.251086-.05565.102912-.08505.218889-.0854.336916V9.69134C2.5874 9.89213 0 11.637 0 13.8457 0 16.1721 2.9275 18 6.66856 18c3.74104 0 6.66854-1.8279 6.66854-4.1543 0-2.188-2.5874-3.93279-6.00168-4.15436V8.04346l6.30178-3.275c.1092-.0581.2009-.14638.2649-.25511.064-.10872.0979-.23368.0979-.36112 0-.12743-.0339-.25239-.0979-.36111-.064-.10873-.1557-.19701-.2649-.25511zM12.0034 13.8457c0 1.3086-2.19395 2.7695-5.33484 2.7695-3.14089 0-5.33485-1.4609-5.33485-2.7695 0-1.3086 1.8872-2.5549 4.66799-2.7696v2.0772c-.17686 0-.34647.0729-.47153.2028-.12506.1298-.19532.3059-.19532.4896 0 .1836.07026.3597.19532.4896.12506.1298.29467.2028.47153.2028h1.33372c.17686 0 .34648-.073.47153-.2028.12506-.1299.19532-.306.19532-.4896 0-.1837-.07026-.3598-.19532-.4896-.12505-.1299-.29467-.2028-.47153-.2028v-2.0772c2.78078.2147 4.66798 1.5579 4.66798 2.7696zM7.33542 6.49251V1.81196l4.50798 2.34027-4.50798 2.34028z" 
      fill={fill} />
  </svg>
);

HoleIcon.defaultProps = {
  fill: '#929290',
};

export default HoleIcon;
