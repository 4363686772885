// @flow

import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';

import Login from 'containers/LoginContainer';
import Register from 'containers/RegisterContainer';
import Session from 'containers/SessionContainer';
import PaymentConfirmation from 'containers/PaymentConfirmationContainer';
import RoutesHeader from 'components/navigation/RoutesHeader';
import {
  HOME_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  SELECT_PLAYERS_PATH,
  PAYMENT_CANCELLATION_PATH,
  PAYMENT_CONFIRMATION_PATH,
} from 'constants/routes';

const Routes = () => (
  <>
    <RoutesHeader />
    <Switch>
      <Route exact path={SELECT_PLAYERS_PATH} component={Session} />
      <Route exact path={LOGIN_PATH} component={Login} />
      <Route exact path={PAYMENT_CANCELLATION_PATH} component={PaymentConfirmation} />
      <Route exact path={PAYMENT_CONFIRMATION_PATH} component={PaymentConfirmation} />
      <Route exact path={REGISTER_PATH} component={Register} />
      <Route exact path={HOME_PATH} component={Login} />
    </Switch>
  </>
);

export default Routes;
