// @flow
import React, { useCallback, useMemo } from 'react';

import RightArrowIcon from 'assets/RightArrowIcon';
import { useTranslation } from 'components/translationProvider/TranslationProvider';

import styles from 'styles/langSwitcher.module.css';

const availableLangs = ['fr', 'en', 'es', 'de'];

/* eslint-disable */
const imageByLang = {
  fr: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAdklEQVR42u3QQQ0AIAwEQUwhABE4xB8SSvDQ302TTfq9GVU1koseDwAAAAAAAAAAAADAf/apzrrvrtkaAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAkASQGgAAAAAAAAAAAAAAoT2Kz+HEy0UcugAAAABJRU5ErkJggg==',
  en: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACb0lEQVR42u2avUoDQRDHrxUCVnkSQdAmjYVtIBCwEHwAn8BnyAMEFBEs0hhT2ekTiGjjB2i0sNAUSSMoWIyZw5XNfbm7M3Mh3AwsKe643f39/5CZ2Y0AIKryiBSAAlAACkABKAAFoAAUgAKoOICndhu+RyOw4/X9AzZ3zyBa6bIME5fTabOGCa75VrdP4f5lApN+H67r9XgO/B33epACYB7iy8nontzCcuNwYQAsrR9A5/gmFhSFNd9/bDZtkWcBPDQafy9KuaEMAGs7gyLV7ZgFgBt863TgqlYDKTdIAnBUPY7R+DMNADe1P7iDr+EQpNwgBcBDdehfPEN94ygNwHwMNyblBm4Avqpv7Z3ba8kGgCPXDa0WyQ2cAEJUT6wlH4CUGzgAUFT3BsDtBioAqupBAELcgMCy3BAKgEt1EgAON4QA4FSdDIDqBh8AEqqzAQh1gysAKdVZAYS4wRWAlOoiAHzd4AJASvVCAFBi/AegpFAAqX5AlYYCUAAKQAEoAP0bVAA8qTCmpZieJgPTWDut9QUQ1wTTdNo8xzQb0+28fsNcagEsSH7bzDMLxwImr7Ps6wC7KsSCCwsv6jkFGYCr6rh4LG2xxHUFYCpMSTeQAPiojk0NbG74NkRMhSnlhiAAIapTW2JSbvAGEKo6R1NUwg3OAKiqc7bFOd3gBIBDde6DEdwUHsJQ3VAIgFN1qaMxqhtyAXCrLnk4SnFDCoCU6mUcj+e6weosJ92QAiClelkXJHzdUFgMcape9hUZVzdkApBQfR6XpFzckAKQpzpeNVvUa3JFbtCLkgpAASgABaAAFIACUAAKQAFUG8APtyfgJ+7gOvsAAAAASUVORK5CYII=',
  es: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAADq0lEQVR42u3ZzWskRRjH8W9Vd897ks4kM8lkNa+aLLKa4GZZomBcL15F2JMHj+Ie9yB4EPTuPyELqwdFInpQhCi7QWEF3V1ifNldN4bsmFfmLTPTXV1dHhL9A4JCJlMPNPSpoD78qrrqaWGMoZtL0uVlASyABbAAFsACWAALYAG6tdx/Xr7LjXXVpWChsS5sAiyABbAAFsACWAAL0NUl/u0Kr4juag8/b/7Hk6A4erpuCQjAgWYoOAgEOCcfwv2vJq5jl4PAJTAuq1s9KC2ZHamSFhFpT+E6GsxpBBBQbaV4tJ+kWk/itxuYikIKwX49Zj/pk+9rMuwr/GwL4tMEIA5HqCmHteuC+E+4J9OYhINxDeV6mqQbs1WSpN5w8Xs4TIE5DQACtAITwkh/k+GLHquhRBcjPC/AaE0YStw9l7NzTR7vV8TqcPJSdjrA0ca29IVLowWvXY44v6AI0jVu3E2S3RH0pXLoksP0YpuFcy5oxSdLLgJ49ZUIKU5GEo4HYMAIcMcMCSVoHQiW3x6gogwD9Yg+1U/+vXfw/CxB7SHf7w0yl3sLeaZGbE7JHiCAqaevsK8GSIt3CSqSB7MVnERE8fYgXrYX706FpC4QDRVIpRXT81ep1mOk8z7oDl8CYVPSXNvgoLzF3VYGrWAnM4F2YmZabTyhMEM9ZHYUbc9l9asMlfYaxk/QfkaSysUnYgkcbztKwf1vBA9WJCbfiw5jYqnwGpCuOdzIF/n042XCTETjsX68nE8UAnmf8s+SX7+WkOzkBAQw+YKmOj7M+ophZr6Nt+4xt7NHfTfB9fOXaBnNhW8/ozAzQrjc5MlShc1bDoNzI0xPRxB2MoAHO/ckm5//QvG5IYZnQd4PyRU1yUaDC5u3CaMU/sBNxB2Fqjn4r2vOzDf5fanMX6HDxCUN7U4FiKCnEDP65suIgQJSf4hQgjgSkHJYLN0iU5G0J3sIjCBTVQiq5BdfpFBQ9OkvIerkBGjoGwVR/ojqH2nkOMQRGANCK0I3h5tJkN2toU0CLQVeErZ/+wBXa/ITdDiAA4/281z78SxKu4z0bvDUuTI//eCg5DAHpUVqlSSDmzeRwTZTzzbZTRe5tvIEAsFodoPJwtaJ+BQe+yCUdBWLY9vExiXrtBm9GFEchz1dYj0KCFSDKVlk0DykMNFmu5LipfFtDJD2whNzH7AdIdsTPEqA/TvcpWUBLIAFsAAWwAJYAHsStAmwABbAAlgAC2ABLIAFsAAWoLvqb7oxbsOqYPZkAAAAAElFTkSuQmCC',
  de: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAb0lEQVR42u3aMQHAQAzEsOOPrZz8Q2FEgwlo9qpdbgAAAAAAAAAAAAAAHAfY1qUAAAAAAAAAAAAAAAAA4G/rVAAAAAAAAAAAAAAAAABQrW+dCgAAAAAAAAAAAAAAAAAAGCUBAAAAAAAAAAAAALjWA6kBEAfC9SDsAAAAAElFTkSuQmCC',
};
/* eslint-enable */

const LangSwitcher = () => {
  const [{ locale }, setLocale] = useTranslation();

  const onChange = useCallback((value) => () => {
    setLocale(value);
  }, [setLocale]);

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  const renderLangs = useMemo(() => availableLangs.map((lang) => (
    <li key={lang}>
      <div>
        <img
          width={24}
          height={24}
          alt={lang}
          onClick={onChange(lang)}
          src={imageByLang[lang]} />
      </div>
    </li>
  )), [locale, onChange]);
  /* eslint-enable jsx-a11y/no-noninteractive-element-interactions */

  return (
    <div className={styles.langs}>
      <button>
        <img width={24} height={24} alt={locale} src={imageByLang[locale]} />
        <RightArrowIcon />
      </button>
      <ul>
        {renderLangs}
      </ul>
    </div>
  );
};

export default LangSwitcher;
