// @flow

import React from 'react';

import Loader from 'components/fragments/Loader';

import STATUS from 'constants/status';

import computeStyles from 'utils/styles';
import styles from 'styles/button.module.css';

import type { StatusType } from 'types/statusType';

type Props = {
  onClick: Function,
  label: string,
  type: string,
  buttonType: string,
  status: StatusType,
  isDisabled: boolean,
};

const Button = ({
  type,
  label,
  status,
  onClick,
  isDisabled,
  buttonType,
}: Props) => {
  const className = computeStyles(
    styles.main,
    {
      [styles.primary]: buttonType === 'primary',
      [styles.secondary]: buttonType === 'secondary',
      [styles.tertiary]: buttonType === 'tertiary',
    },
    isDisabled && styles.disabled,
  );

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={isDisabled}
      className={className}>
      {status === STATUS.LOADING ? <Loader /> : label}
    </button>
  );
};

Button.defaultProps = {
  label: 'button',
  buttonType: 'primary',
  type: 'button',
  status: STATUS.DEFAULT,
  isDisabled: false,
};

export default Button;
