// @flow

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AddFidelityCard from 'containers/AddFidelityCardContainer';
import Input from 'components/fragments/Input';
import Button from 'components/fragments/Button';
import { useTranslation } from 'components/translationProvider/TranslationProvider';
import { isValidName, validate } from 'utils/validators';
import styles from 'styles/customizePlayer.module.css';

import type { SessionPlayerType } from 'types/sessionType';

type Props = {
  firstName: string,
  lastName: string,
  onComplete: (player: SessionPlayerType) => any,
  playerIndex: number,
  hideModal: Function,
  connectedUserCard?: string,
};

const CustomizePlayer = ({
  firstName: defaultFirstName,
  lastName: defaultLastName,
  onComplete,
  playerIndex,
  hideModal,
  connectedUserCard,
}: Props) => {
  const [lastName, setLastName]: [string, Function] = useState(defaultLastName);
  const [firstName, setFirstName]: [string, Function] = useState(defaultFirstName);
  const [{ t }] = useTranslation();

  useEffect(() => {
    setFirstName(defaultFirstName);
    setLastName(defaultLastName);
  }, [defaultFirstName, defaultLastName, playerIndex, setFirstName, setLastName]);

  const validateFirstName = useMemo(
    () => validate(isValidName, t('input.firstName.error_message')),
    [t],
  );

  const validateLastName = useMemo(
    () => validate(isValidName, t('input.lastName.error_message')),
    [t],
  );

  const onSubmit = useCallback(() => {
    onComplete({ firstName, lastName });
  }, [firstName, lastName, onComplete]);

  return (
    <div>
      <div className={styles.container}>
        <Input
          asterisk
          validate={validateLastName(lastName)}
          label={t('input.lastName.label')}
          placeholder={t('input.lastName.placeholder')}
          type={'text'}
          name={'lastName'}
          autoComplete={'family-name'}
          initialValue={lastName}
          containerClassNames={[styles.input, styles.first_input]}
          onChange={setLastName} />
        <Input
          asterisk
          validate={validateFirstName(firstName)}
          label={t('input.firstName.label')}
          placeholder={t('input.firstName.placeholder')}
          type={'text'}
          name={'firstName'}
          autoComplete={'given-name'}
          initialValue={firstName}
          containerClassNames={[styles.input]}
          onChange={setFirstName} />
      </div>
      {playerIndex !== 0 && <AddFidelityCard playerIndex={playerIndex} connectedUserCard={connectedUserCard} />}
      <div className={styles.buttons}>
        <div className={styles.buttons__item}>
          <Button
            buttonType={'secondary'}
            label={t('session.create.cancel_customized_player')}
            onClick={hideModal} />
        </div>
        <div className={styles.buttons__item}>
          <Button
            buttonType={'primary'}
            label={t('session.create.submit_customized_player')}
            onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default React.memo<Props>(CustomizePlayer);
