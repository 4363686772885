// @flow

import React from 'react';

type Props = {
  fill: string,
};
 
const TimeIcon = ({ fill }: Props) => (
  <svg width={'20'} height={'18'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path 
      d="M14.8334 2.33335h-1.6667v-.83333c0-.22101-.0877-.43298-.244-.589255-.1563-.156281-.3683-.244078-.5893-.244078-.221 0-.433.087797-.5892.244078-.1563.156275-.2441.368245-.2441.589255v.83333H6.50008v-.83333c0-.22101-.0878-.43298-.24408-.589255-.15628-.156281-.36824-.244078-.58925-.244078-.22102 0-.43298.087797-.58926.244078-.15628.156275-.24407.368245-.24407.589255v.83333H3.16675c-.66304 0-1.29893.2634-1.76777.73224-.46884.46884-.732232 1.10472-.732232 1.76776V14.8334c0 .663.263392 1.2989.732232 1.7677.46884.4689 1.10473.7323 1.76777.7323H14.8334c.6631 0 1.2989-.2634 1.7678-.7323.4688-.4688.7322-1.1047.7322-1.7677V4.83335c0-.66304-.2634-1.29892-.7322-1.76776-.4689-.46884-1.1047-.73224-1.7678-.73224zm.8334 12.50005c0 .221-.0878.4329-.2441.5892-.1563.1563-.3683.2441-.5893.2441H3.16675c-.22102 0-.43298-.0878-.58926-.2441-.15628-.1563-.24407-.3682-.24407-.5892V9.00002H15.6668v5.83338zm0-7.50005H2.33342v-2.5c0-.22101.08779-.43297.24407-.58925.15628-.15628.36824-.24408.58926-.24408h1.66667v.83333c0 .22102.08779.43298.24407.58926.15628.15628.36824.24408.58926.24408.22101 0 .43297-.0878.58925-.24408.15628-.15628.24408-.36824.24408-.58926v-.83333h5.00002v.83333c0 .22102.0878.43298.2441.58926.1562.15628.3682.24408.5892.24408.221 0 .433-.0878.5893-.24408.1563-.15628.244-.36824.244-.58926v-.83333h1.6667c.221 0 .433.0878.5893.24408.1563.15628.2441.36824.2441.58925v2.5z" 
      fill={fill} />
  </svg>
);
 
TimeIcon.defaultProps = {
  fill: '#929290',
};

export default TimeIcon;
