// @flow

import React, { useState, useCallback } from 'react';
import ReactSelect from 'react-select';

import { useTranslation } from 'components/translationProvider/TranslationProvider';
import STATUS from 'constants/status';
import { COLORS } from 'constants/styles';
import computeStyles from 'utils/styles';
import styles from 'styles/select.module.css';
import Error from 'assets/error';

import type { StatusType } from 'types/statusType';

type OptionValueType = {
  label: string,
  value: string,
};

const DEFAULT_OPTION = {
  value: null,
  label: 'select.default_label',
};

type Props = {
  name: string,
  label: string,
  type?: string,
  status: StatusType,
  placeholder?: string,
  onChange?: Function,
  validate: {| valid: boolean, error?: ?string |},
  options: OptionValueType[],
  asterisk?: boolean,
  initialValue?: OptionValueType,
};

const Select = ({
  label,
  status,
  name,
  options,
  onChange: onChangeProps,
  validate,
  asterisk,
  initialValue,
  ...rest
}: Props) => {
  const [{ t }] = useTranslation();
  const initValue = initialValue ? { ...initialValue, label: t(initialValue.label) } : null;

  const [value, setValue] = useState<?OptionValueType>(initValue);
  const [error, setError] = useState<string | null>(null);

  const onChange = useCallback(
    (selectValue: { label: string, value: string }) => {
      setValue(selectValue);
      onChangeProps && onChangeProps(selectValue.value, name);
    },
    [setValue, onChangeProps, name],
  );

  const onFocus = useCallback(() => setError(''), [setError]);

  const onBlur = useCallback(() => {
    const { valid, error: parentError } = validate;
    if (!valid) {
      setError(parentError || '');
    }
  }, [validate, setError]);

  const htmlProps = rest;

  const className = computeStyles(styles.select, {
    [styles.error]: error,
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? COLORS.selected
        : provided.backgroundColor,
      color: state.isSelected ? 'white' : 'grey',
      padding: 20,
    }),
    control: () => ({
      width: '100%',
      display: 'flex',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
  };

  const optionsWithTranslate = options.map((opt) => ({ ...opt, label: t(opt.label) }));

  return (
    <div className={styles.main}>
      <div className={`${styles.label} ${asterisk ? styles.required : ''}`}>
        <label htmlFor={name}>{label}</label>
      </div>
      <ReactSelect
        styles={customStyles}
        className={className}
        components={{ IndicatorSeparator: () => null }}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        label={label}
        status={status}
        options={optionsWithTranslate}
        onBlur={onBlur}
        onFocus={onFocus}
        // $FlowFixMe
        {...htmlProps}>
        <option value={DEFAULT_OPTION.value}>{t(DEFAULT_OPTION.label)}</option>
        {optionsWithTranslate.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </ReactSelect>
      {error && <Error message={error} />}
    </div>
  );
};

Select.defaultProps = {
  label: 'select',
  status: STATUS.DEFAULT,
  onChange: () => {},
  validate: { valid: true, error: '' },
  options: [],
};

export default Select;
