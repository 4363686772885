// @flow
// @xxx verify that purchase tunnel was initialized with token

import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import GenericError from 'components/fragments/GenericError';
import Loader from 'components/fragments/Loader';
import STATUS from 'constants/status';
import { URL_KADY_PARAM, URL_TEETIME_PARAM } from 'constants/config';
import { useQuery } from 'hooks';
import { createSession, extractSessionToken } from 'actions/sessionActions';
import { useTranslation } from 'components/translationProvider/TranslationProvider';

import type { StateType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';

const DEFAULT_DEV_TOKEN = process.env.NODE_ENV === 'development' && process.env.REACT_APP_DEV_TOKEN;

type DispatchProps = {
  createSession: () => any,
  extractSessionToken: (token: string) => any,
};

type StateProps = {
  isLogged: boolean,
  hasCreatedSession: boolean,
  sessionStatus: StatusType,
  token: string,
};

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: StateType): StateProps => ({
  isLogged: state.userState.isLogged,
  hasCreatedSession: state.sessionState.session !== null,
  sessionStatus: state.sessionState.status,
  token: state.sessionState.token,
});

const mapDispatchToProps: DispatchProps = {
  createSession,
  extractSessionToken,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const onlyValidInitialization = (Component: any) => connector(({
  createSession: propsCreateSession,
  extractSessionToken: propsExtractSessionToken,
  token,
  isLogged,
  hasCreatedSession,
  sessionStatus,
}: Props) => {
  const [{ t }] = useTranslation();
  const query = useQuery();
  const isValid = !isLogged || hasCreatedSession;

  const paramsToken = useMemo(() => {
    const paramToken = [
      query.get(URL_KADY_PARAM),
      query.get(URL_TEETIME_PARAM),
    ].find((pt: ?string) => !!pt);

    return paramToken || DEFAULT_DEV_TOKEN;
  }, [query]);

  useEffect(() => {
    if (!token && paramsToken) {
      propsExtractSessionToken(paramsToken);
    }
  }, [token, paramsToken, propsExtractSessionToken]);

  useEffect(() => {
    if (isValid || !token || sessionStatus === STATUS.FAILURE || sessionStatus === STATUS.LOADING) {
      // @xxx only create session when user is logged and hasn't already create a session
      return;
    }

    propsCreateSession();
  }, [isValid, token, propsCreateSession, sessionStatus]);

  if (isValid) {
    return <Component />;
  } if (sessionStatus === STATUS.LOADING || sessionStatus === STATUS.DEFAULT) {
    return <Loader primaryColor />;
  }

  return <GenericError msg={t('initialization.error_message')} />;
});

export default onlyValidInitialization;
