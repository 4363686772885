// @flow

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  type Node,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from 'i18n-js';
import defaultT from 'utils/i18n';

import { selectLocale } from 'selectors/localeSelector';
import { setLocale as setLocaleAction } from 'actions/localeActions';

import fr from 'translations/fr.json';
import en from 'translations/en.json';
import es from 'translations/es.json';
import de from 'translations/de.json';

const StateContext = createContext<any>([{ t: defaultT, locale: 'fr' }, () => {}]);

type Props = {
  children: Node;
};

const TranslationProvider = ({ children }: Props) => {
  const localeProps = useSelector(selectLocale);
  const dispatch = useDispatch();

  const [flag, setFlag] = useState<boolean>(false);
  const [locale, setStateLocale] = useState<'fr' | 'en' | 'es' | 'de'>(localeProps);
  const setLocale = useCallback((newLocale: 'fr' | 'en' | 'es' | 'de') => {
    i18n.locale = newLocale;

    setStateLocale(newLocale);
    dispatch(setLocaleAction(newLocale));
  }, [setStateLocale, dispatch]);

  useEffect(() => {
    if (localeProps && !flag) {
      setFlag(true);
      setLocale(localeProps);
    }
  }, [localeProps, flag, setLocale, setFlag]);

  i18n.fallbacks = true;
  i18n.translations = {
    fr,
    en,
    es,
    de,
  };

  i18n.missingTranslation = () => {
    console.warn('Missing translation'); // eslint-disable-line
    return '';
  };

  const t = useCallback((key: string, options?: any = {}) => i18n.t(key, options), []);

  return (
    <StateContext.Provider value={[
      { locale, t },
      setLocale,
    ]}>
      {children}
    </StateContext.Provider>
  );
};

export const useTranslation = () => useContext(StateContext);

export default TranslationProvider;
