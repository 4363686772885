// @flow

export { default as CONSUMER_CONFIG } from 'assets/config/consumer.json';

export const PERSIST_KEY = 'cNoqLzAkGLjOMKSuBFHgZYKG';

export const URL_KADY_PARAM = 'kadyToken';
export const URL_TEETIME_PARAM = 'teetimeToken';
export const URL_USER_PARAM = 'userToken';
export const URL_USER_EMAIL_PARAM = 'userEmail';
export const URL_LOCALE_PARAM = 'locale';

export const RETURN_URL_LCG = String(process.env.REACT_APP_LCG_RETURN_URL) || 'https://leclub-golf.com/';
