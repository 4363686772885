// @flow

import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { useTranslation } from 'components/translationProvider/TranslationProvider';
import RightArrowIcon from 'assets/RightArrowIcon';
import {
  HOME_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  SELECT_PLAYERS_PATH,
  PAYMENT_CONFIRMATION_PATH,
} from 'constants/routes';
import { CLASSNAME } from 'constants/styles';
import computeStyles from 'utils/styles';
import styles from 'styles/breadcrumbs.module.css';

const INDEX_BY_PATH = {
  [HOME_PATH]: 0,
  [LOGIN_PATH]: 0,
  [REGISTER_PATH]: 0,
  [SELECT_PLAYERS_PATH]: 1,
  [PAYMENT_CONFIRMATION_PATH]: 2,
};

const BREADCRUMBS = [
  { path: LOGIN_PATH, label: 'breadcrumbs.login' },
  { path: SELECT_PLAYERS_PATH, label: 'breadcrumbs.players' },
  { path: PAYMENT_CONFIRMATION_PATH, label: 'breadcrumbs.confirm' },
];

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const [{ t }] = useTranslation();

  const selectedIndex = pathname in INDEX_BY_PATH ? INDEX_BY_PATH[pathname] : null;

  if (selectedIndex === null) {
    return null;
  }

  return (
    <>
      <div className={computeStyles(styles.container, CLASSNAME.onlyDesktop)}>
        {BREADCRUMBS.map(({ label, path }, index) => (
          <div className={styles.item} key={`breadcrumb-${label}`}>
            {index !== 0 && (
              <div className={styles.separator}>
                <RightArrowIcon />
              </div>
            )}
            {/* @xxx only allow redirection to login screen */}
            {index < selectedIndex && index === INDEX_BY_PATH[HOME_PATH]
              ? (
                <Link to={path} className={computeStyles(styles.text)}>
                  {`${index + 1}. ${t(label)}`}
                </Link>
              )
              : (
                <p className={computeStyles(styles.text, index === selectedIndex && styles.selected)}>
                  {`${index + 1}. ${t(label)}`}
                </p>
              )}
          </div>
        ))}
      </div>
      <div className={computeStyles(styles.container, CLASSNAME.onlyMobile)}>
        <p className={computeStyles(styles.text)}>
          {`${selectedIndex + 1}/${BREADCRUMBS.length}`}
        </p>
        <p className={computeStyles(styles.text, styles.dot)}>•</p>
        <p className={computeStyles(styles.text, styles.selected)}>
          {`${t(BREADCRUMBS[selectedIndex].label)}`}
        </p>
      </div>
    </>
  );
};

export default React.memo<{}>(Breadcrumbs);
