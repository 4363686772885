// @flow

import React from 'react';

import styles from 'styles/error.module.css';

type Props = {
  message: string;
  color: string;
}

const Error = ({ message, color }: Props) => (
  <div className={styles.main}>
    <svg width={'18'} height={'18'} viewBox={'0 0 18 18'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
      <path
        // eslint-disable-next-line max-len
        d={'M9 0.666668C6.78986 0.666668 4.67025 1.54464 3.10744 3.10745C1.54464 4.67025 0.666668 6.78986 0.666668 9C0.666668 11.2101 1.54464 13.3298 3.10744 14.8926C4.67025 16.4554 6.78986 17.3333 9 17.3333C11.2101 17.3333 13.3298 16.4554 14.8926 14.8926C16.4554 13.3298 17.3333 11.2101 17.3333 9C17.3333 6.78986 16.4554 4.67025 14.8926 3.10745C13.3298 1.54464 11.2101 0.666668 9 0.666668ZM9 2.33333C10.7681 2.33333 12.4638 3.03571 13.714 4.28596C14.9643 5.5362 15.6667 7.23189 15.6667 9C15.6667 10.7681 14.9643 12.4638 13.714 13.714C12.4638 14.9643 10.7681 15.6667 9 15.6667C7.23189 15.6667 5.5362 14.9643 4.28596 13.714C3.03571 12.4638 2.33333 10.7681 2.33333 9C2.33333 7.23189 3.03571 5.5362 4.28596 4.28596C5.5362 3.03571 7.23189 2.33333 9 2.33333ZM9 9.83334C9.22101 9.83334 9.43298 9.74554 9.58926 9.58926C9.74554 9.43298 9.83333 9.22102 9.83333 9V5.66667C9.83333 5.44565 9.74554 5.23369 9.58926 5.07741C9.43298 4.92113 9.22101 4.83333 9 4.83333C8.77899 4.83333 8.56703 4.92113 8.41075 5.07741C8.25447 5.23369 8.16667 5.44565 8.16667 5.66667V9C8.16667 9.22102 8.25447 9.43298 8.41075 9.58926C8.56703 9.74554 8.77899 9.83334 9 9.83334ZM9 13.1667C9.22101 13.1667 9.43298 13.0789 9.58926 12.9226C9.74554 12.7663 9.83333 12.5543 9.83333 12.3333C9.83333 12.1123 9.74554 11.9004 9.58926 11.7441C9.43298 11.5878 9.22101 11.5 9 11.5C8.77899 11.5 8.56703 11.5878 8.41075 11.7441C8.25447 11.9004 8.16667 12.1123 8.16667 12.3333C8.16667 12.5543 8.25447 12.7663 8.41075 12.9226C8.56703 13.0789 8.77899 13.1667 9 13.1667Z'}
        fill={color} />
    </svg>
    <span className={styles.message}>{message}</span>
  </div>
);

Error.defaultProps = {
  message: 'error',
  color: '#D81F1F',
};

export default Error;
