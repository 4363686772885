// @flow

import {
  CREATE_STRIPE_SESSION,
  CREATE_STRIPE_SESSION_SUCCESS,
  CREATE_STRIPE_SESSION_FAILURE,
  CREATE_STRIPE_SESSION_EXPIRED,
} from 'actions/paymentActions';

import type { ActionType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';

import STATUS from 'constants/status';

export type StateType = {
  status: StatusType,
};

const initialState: StateType = {
  status: STATUS.DEFAULT,
  stripeSessionId: '',
};

export default function paymentState(state: StateType = initialState, action: ActionType) {
  switch (action.type) {
    case CREATE_STRIPE_SESSION:
      return {
        ...state,
        status: STATUS.LOADING,
      };
    case CREATE_STRIPE_SESSION_SUCCESS: {
      return {
        ...state,
        status: STATUS.SUCCESS,
      };
    }
    case CREATE_STRIPE_SESSION_EXPIRED: {
      return {
        ...state,
        status: STATUS.EXPIRED,
      };
    }
    case CREATE_STRIPE_SESSION_FAILURE: {
      return {
        ...state,
        status: STATUS.FAILURE,
      };
    }
    default:
      return state;
  }
}
