// @flow

import { connect } from 'react-redux';

import Session, {
  type DispatchProps,
  type StateProps,
} from 'components/session/Session';
import { showModal, hideModal } from 'actions/modalActions';
import { updateSessionPlayer as updateSession } from 'actions/sessionActions';
import { createStripeSession } from 'actions/paymentActions';

import { onlyLogged, onlyValidInitialization } from 'hoc';

import type { StateType } from 'types/actionsType';

const mapStateToProps = (state: StateType): StateProps => ({
  status: state.sessionState.status,
  paymentStatus: state.paymentState.status,
  session: state.sessionState.session,
  userEmail: state.userState.email,
});

const mapDispatchToProps: DispatchProps = {
  createStripeSession,
  showModal,
  hideModal,
  updateSession,
};

export default onlyLogged(
  onlyValidInitialization(
    connect(mapStateToProps, mapDispatchToProps)(Session),
  ),
);
