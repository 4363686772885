// @flow

import { SHOW_SNACK_BAR, HIDE_SNACK_BAR } from 'actions/snackBarActions';

import type { ActionType } from 'types/actionsType';

export type StateType = {
  isOpened: boolean,
  content: string,
  duration: ?number,
};

const initialState: StateType = {
  isOpened: false,
  content: '',
  duration: undefined,
};

export default function snackBarState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case SHOW_SNACK_BAR: {
      const { content, duration } = action.payload;
      return {
        ...state,
        content,
        duration,
        isOpened: true,
      };
    }
    case HIDE_SNACK_BAR: {
      return {
        ...state,
        duration: undefined,
        isOpened: false,
      };
    }
    default:
      return state;
  }
}
