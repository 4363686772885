// @flow

export const GENDERS = [
  { value: 'M', label: 'register.gender.sir' },
  { value: 'MLLE', label: 'register.gender.miss' },
  { value: 'MME', label: 'register.gender.mrs' },
  { value: 'COMPANY', label: 'register.gender.company' },
];

export const DEFAULT_GENDER = GENDERS[0].value;

export const LANGUAGES = [
  { value: 'fr', label: 'register.language.fr' },
  { value: 'en', label: 'register.language.en' },
  { value: 'es', label: 'register.language.es' },
  { value: 'de', label: 'register.language.de' },
];
