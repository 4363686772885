// @flow
import axios from 'axios';
import ApiExecutor from '@dotmind/utils/dist/react/ApiExecutor';

import { TIMEOUT } from 'constants/api';

function apiInstance(baseURL: string, options: Object = {}): Object {
  return axios.create({
    baseURL,
    timeout: TIMEOUT,
    ...options,
  });
}

export default function api(baseURL: string) {
  const instance = apiInstance(baseURL);
  const executor = new ApiExecutor(instance);

  function handle(request) {
    return request.then((ref) => {
      const { data, _bodyInit: bodyInit } = ref;
      return bodyInit || data;
    });
  }

  function setApiKey(apiKey: string) {
    instance.defaults.headers.common['x-api-key'] = apiKey;
  }

  function patch(url, params, config) {
    return handle(instance.patch(url, params, config));
  }

  return {
    ...executor,
    patch,
    setApiKey,
  };
}
