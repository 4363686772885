// @flow

import { connect } from 'react-redux';

import AddFidelityCard, {
  type DispatchProps,
  type StateProps,
} from 'components/session/AddFidelityCard';
import { addFidelityCard, deleteCardNumber } from 'actions/fidelityCardActions';

import type { StateType } from 'types/actionsType';

const mapStateToProps = (state: StateType): StateProps => ({
  message: state.fidelityCardState.message,
  session: state.sessionState.session,
});

const mapDispatchToProps: DispatchProps = {
  addFidelityCard,
  deleteCardNumber,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFidelityCard);
