// @flow

import {
  LIST_ORGANIZATIONS,
  LIST_ORGANIZATIONS_SUCCESS,
  LIST_ORGANIZATIONS_FAILURE,
} from 'actions/organizationActions';

import STATUS from 'constants/status';

import type { ActionType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';
import type { OrganizationChoice } from 'types/organizationType';

export type StateType = {
  organizations: OrganizationChoice[],
  status: StatusType,
};

const initialState: StateType = {
  organizations: [],
  status: STATUS.DEFAULT,
};

export default function organizationState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case LIST_ORGANIZATIONS:
      return {
        ...state,
        status: STATUS.LOADING,
      };
    case LIST_ORGANIZATIONS_SUCCESS: {
      const { payload: { organizations = [] } } = action;

      return {
        ...state,
        organizations,
        status: STATUS.SUCCESS,
      };
    }
    case LIST_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        status: STATUS.FAILURE,
      };
    default:
      return state;
  }
}
