// @flow

import React, { useCallback, useEffect, useState } from 'react';
import computeStyles from 'utils/styles';

import Error from 'assets/error';

import styles from 'styles/input.module.css';

type Props = {
  initialValue?: string;
  name: string;
  label: string;
  onChange?: Function;
  placeholder?: string;
  type?: string;
  validate: {| valid: boolean, error?: ?string |};
  containerClassNames: string[],
  autoComplete?: string,
  asterisk?: boolean,
};

const Input = ({
  name,
  label,
  initialValue,
  onChange: onChangeProps,
  validate,
  containerClassNames,
  asterisk,
  ...rest
}: Props) => {
  const [value, setValue]: [?string, Function] = useState(initialValue);
  const [error, setError] = useState<string>('');

  const htmlProps = rest;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = useCallback((event: SyntheticInputEvent<HTMLInputElement>) => {
    const { target: { value: inputValue } } = event;

    setValue(inputValue);
    onChangeProps && onChangeProps(inputValue, name);
  }, [setValue, onChangeProps, name]);

  const onFocus = useCallback(() => setError(''), [setError]);

  const onBlur = useCallback(() => {
    const { valid, error: parentError } = validate;
    if (!valid) {
      setError(parentError || '');
    }
  }, [validate, setError]);

  const input = computeStyles(styles.input, {
    [styles.error]: error,
  });

  return (
    <div className={computeStyles(styles.main, ...containerClassNames)}>
      <div className={computeStyles(styles.label, asterisk && styles.required)}>
        <label htmlFor={name}>{label}</label>
      </div>
      <input
        id={name}
        name={name}
        value={value}
        className={input}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        // $FlowFixMe
        {...htmlProps} />
      {error && <Error message={error} />}
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  initialValue: '',
  placeholder: 'placeholder',
  onChange: () => {},
  validate: { valid: true, error: '' },
  containerClassNames: [],
};

export default Input;
