// @flow

import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';

import styles from 'styles/register.module.css';
import Input from 'components/fragments/Input';
import Button from 'components/fragments/Button';
import Select from 'components/fragments/Select';
import Checkbox from 'components/fragments/Checkbox';
import { useTranslation } from 'components/translationProvider/TranslationProvider';
import {
  isValidEmail,
  isValidName,
  isValidPassword,
  isValidPhoneNumber,
  isValidSelect,
  validate,
} from 'utils/validators';
import {
  GENDERS,
  DEFAULT_GENDER,
  LANGUAGES,
} from 'assets/config/selectOptions';
import { CONSUMER_CONFIG } from 'constants/config';
import { LOGIN_PATH } from 'constants/routes';
import STATUS from 'constants/status';

import type { StatusType } from 'types/statusType';

export type DispatchProps = {
  register: Function,
};

export type StateProps = {
  status: StatusType,
};

type Props = DispatchProps & StateProps;

const Register = ({ register, status }: Props) => {
  const [{ t }] = useTranslation();
  const [gender, setGender]: [string, Function] = useState(DEFAULT_GENDER);
  const [lastName, setLastName]: [string, Function] = useState('');
  const [firstName, setFirstName]: [string, Function] = useState('');
  const [password, setPassword]: [string, Function] = useState('');
  const [confirmPassword, setConfirmPassword]: [string, Function] = useState(
    '',
  );
  const [email, setEmail]: [string, Function] = useState('');
  const [phoneNumber, setPhoneNumber]: [string, Function] = useState('');
  const [language, setLanguage]: [string, Function] = useState('');
  const [optEmail, setOptEmail] = useState<boolean>(false);
  const [optSms, setOptSms] = useState<boolean>(false);

  const validateGender = useMemo(
    () => validate(
      isValidSelect(GENDERS.map(({ value }) => value)),
      t('select.gender.error_message'),
    ),
    [t],
  );

  const validateLanguage = useMemo(
    () => validate(
      isValidSelect(LANGUAGES.map(({ value }) => value)),
      t('select.language.error_message'),
    ),
    [t],
  );

  const validateEmail = useMemo(
    () => validate(isValidEmail, t('input.email.error_message')),
    [t],
  );

  const validatePassword = useMemo(
    () => validate(isValidPassword, t('input.password.error_message')),
    [t],
  );

  const validateConfirmPassword = useMemo(
    () => validate(
      (value: string) => isValidPassword(value) && value === password,
      t('input.confirmPassword.error_message'),
    ),
    [password, t],
  );

  const validateFirstName = useMemo(
    () => validate(isValidName, t('input.firstName.error_message')),
    [t],
  );

  const validateLastName = useMemo(
    () => validate(isValidName, t('input.lastName.error_message')),
    [t],
  );

  const validatePhoneNumber = useMemo(
    () => validate(isValidPhoneNumber, t('input.phoneNumber.error_message')),
    [t],
  );

  const canRegisterAccount = useMemo(
    () => validateLanguage(language).valid
      && validateEmail(email).valid
      && validatePassword(password).valid
      && validateConfirmPassword(confirmPassword).valid
      && validateLastName(lastName).valid
      && validateFirstName(firstName).valid,
    [
      validateLanguage,
      validateEmail,
      validatePassword,
      validateConfirmPassword,
      validateLastName,
      validateFirstName,
      language,
      email,
      password,
      confirmPassword,
      lastName,
      firstName,
    ],
  );

  const handleChange = useCallback(
    (fn: Function) => (value: string) => fn(value),
    [],
  );

  const createNewAccount = (e) => {
    e.preventDefault();
    register({
      email,
      password,
      firstName,
      lastName,
      phoneNumber,
      gender,
      language,
      emailOptin: optEmail,
      smsOptin: optSms,
    });
  };

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.title}>
          <p>{t('register.title', { clientName: CONSUMER_CONFIG.name })}</p>
        </div>
        <form onSubmit={createNewAccount}>
          <div className={styles.row}>
            <div className={styles.selectWrapper}>
              <Select
                validate={validateGender(gender)}
                label={t('input.gender.label')}
                options={GENDERS}
                name={'gender'}
                initialValue={GENDERS[0]}
                autoComplete={'honorific-prefix'}
                onChange={handleChange(setGender)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                asterisk
                validate={validateLastName(lastName)}
                label={t('input.lastName.label')}
                placeholder={t('input.lastName.placeholder')}
                type={'text'}
                name={'lastName'}
                autoComplete={'family-name'}
                initialValue={lastName}
                onChange={handleChange(setLastName)} />
            </div>
            <div className={styles.inputSecondWrapper}>
              <Input
                asterisk
                validate={validateFirstName(firstName)}
                label={t('input.firstName.label')}
                placeholder={t('input.firstName.placeholder')}
                type={'text'}
                name={'firstName'}
                autoComplete={'given-name'}
                initialValue={firstName}
                onChange={handleChange(setFirstName)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                asterisk
                validate={validateEmail(email)}
                label={t('input.email.label')}
                placeholder={t('input.email.placeholder')}
                type={'email'}
                name={'email'}
                autoComplete={'email'}
                initialValue={email}
                onChange={handleChange(setEmail)} />
            </div>
            <div className={styles.inputSecondWrapper}>
              <Input
                validate={validatePhoneNumber(phoneNumber)}
                label={t('input.phoneNumber.label')}
                placeholder={t('input.phoneNumber.placeholder')}
                type={'text'}
                name={'phoneNumber'}
                autoComplete={'tel'}
                initialValue={phoneNumber}
                onChange={handleChange(setPhoneNumber)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.inputWrapper}>
              <Input
                asterisk
                validate={validatePassword(password)}
                label={t('input.password.label')}
                placeholder={t('input.password.placeholder')}
                type={'password'}
                name={'password'}
                initialValue={password}
                autoComplete={'new-password'}
                onChange={handleChange(setPassword)} />
            </div>
            <div className={styles.inputSecondWrapper}>
              <Input
                asterisk
                validate={validateConfirmPassword(confirmPassword)}
                label={t('input.confirmPassword.label')}
                placeholder={t('input.confirmPassword.placeholder')}
                type={'password'}
                name={'confirmPassword'}
                autoComplete={'new-password'}
                initialValue={confirmPassword}
                onChange={handleChange(setConfirmPassword)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.selectWrapper}>
              <Select
                asterisk
                validate={validateLanguage(language)}
                label={t('input.language.label')}
                options={LANGUAGES}
                name={'language'}
                onChange={handleChange(setLanguage)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.item}>
              <Checkbox
                name={'optEmail'}
                label={t('input.optin_email.label', { clientName: CONSUMER_CONFIG.name })}
                initialValue={optEmail}
                onChange={handleChange(setOptEmail)} />
            </div>
          </div>
          <div className={styles.row}>
            <div className={styles.item}>
              <Checkbox
                name={'optSms'}
                label={t('input.optin_sms.label', { clientName: CONSUMER_CONFIG.name })}
                initialValue={optSms}
                onChange={handleChange(setOptSms)} />
            </div>
          </div>
          <div className={styles.registerContainer}>
            <div className={styles.submitButton}>
              <Button
                status={status}
                isDisabled={!canRegisterAccount || status === STATUS.LOADING}
                label={t('login.right.button')}
                buttonType={'primary'}
                onClick={createNewAccount} />
            </div>
            <div className={styles.alreadyClient}>
              <span>
                <Link to={LOGIN_PATH}>{t('register.login')}</Link>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
