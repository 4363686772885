// @flow

import globalValidator from 'validator';

export const isValidEmail = globalValidator.isEmail;
export const isValidOrganizationId = (value: string) => !!value;
export const isValidPhoneNumber = globalValidator.isMobilePhone;
export const isValidPassword = (value: string) => value.length > 6;
export const isValidName = (value: string) => !!value.length;
export const isValidSelect = (validValues: string[]) => (value: string) => validValues.includes(value);

export const validate = (validator: (value: string) => boolean, errorMsg: string) => (
  (value: string) => {
    const isValid = validator(value);
    return { valid: isValid, error: isValid ? null : errorMsg };
  }
);
