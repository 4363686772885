// @flow

import React from 'react';

type Props = {
  fill: string,
};

const WarningIcon = ({ fill }: Props) => (
  <svg width={'18'} height={'18'} viewBox={'0 0 18 18'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      // eslint-disable-next-line max-len
      d={'M9.00008 17.3333C6.78994 17.3333 4.67033 16.4553 3.10752 14.8925C1.54472 13.3297 0.666748 11.2101 0.666748 8.99996C0.666748 6.78982 1.54472 4.67021 3.10752 3.1074C4.67033 1.5446 6.78994 0.666626 9.00008 0.666626C11.2102 0.666626 13.3298 1.5446 14.8926 3.1074C16.4554 4.67021 17.3334 6.78982 17.3334 8.99996C17.3334 11.2101 16.4554 13.3297 14.8926 14.8925C13.3298 16.4553 11.2102 17.3333 9.00008 17.3333ZM9.00008 15.6666C10.7682 15.6666 12.4639 14.9642 13.7141 13.714C14.9644 12.4638 15.6667 10.7681 15.6667 8.99996C15.6667 7.23185 14.9644 5.53616 13.7141 4.28591C12.4639 3.03567 10.7682 2.33329 9.00008 2.33329C7.23197 2.33329 5.53628 3.03567 4.28604 4.28591C3.03579 5.53616 2.33341 7.23185 2.33341 8.99996C2.33341 10.7681 3.03579 12.4638 4.28604 13.714C5.53628 14.9642 7.23197 15.6666 9.00008 15.6666ZM9.00008 8.16663C9.2211 8.16663 9.43306 8.25442 9.58934 8.4107C9.74562 8.56698 9.83342 8.77895 9.83342 8.99996V12.3333C9.83342 12.5543 9.74562 12.7663 9.58934 12.9225C9.43306 13.0788 9.2211 13.1666 9.00008 13.1666C8.77907 13.1666 8.56711 13.0788 8.41083 12.9225C8.25455 12.7663 8.16675 12.5543 8.16675 12.3333V8.99996C8.16675 8.77895 8.25455 8.56698 8.41083 8.4107C8.56711 8.25442 8.77907 8.16663 9.00008 8.16663ZM9.00008 4.83329C9.2211 4.83329 9.43306 4.92109 9.58934 5.07737C9.74562 5.23365 9.83342 5.44561 9.83342 5.66663C9.83342 5.88764 9.74562 6.0996 9.58934 6.25588C9.43306 6.41216 9.2211 6.49996 9.00008 6.49996C8.77907 6.49996 8.56711 6.41216 8.41083 6.25588C8.25455 6.0996 8.16675 5.88764 8.16675 5.66663C8.16675 5.44561 8.25455 5.23365 8.41083 5.07737C8.56711 4.92109 8.77907 4.83329 9.00008 4.83329Z'}
      fill={fill} />
  </svg>
);

WarningIcon.defaultProps = {
  fill: '#E99B24',
};

export default WarningIcon;
