// @flow

import {
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  CREATE_SESSION_FAILURE,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_PLAYER_SUCCESS,
  UPDATE_SESSION_PLAYER_FAILURE,
  GET_SESSION,
  GET_SESSION_SUCCESS,
  GET_SESSION_FAILURE,
  EXTRACT_SESSION_TOKEN,
} from 'actions/sessionActions';

import STATUS from 'constants/status';

import type { ActionType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';
import type { SessionType } from 'types/sessionType';

export type StateType = {
  token: string,
  status: StatusType,
  session: SessionType | null,
};

const initialState: StateType = {
  token: '',
  status: STATUS.DEFAULT,
  session: null,
};

export default function sessionState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case EXTRACT_SESSION_TOKEN: {
      const { token } = action.payload;
      return {
        ...state,
        token,
      };
    }

    case CREATE_SESSION:
    case GET_SESSION: {
      return {
        ...state,
        status: STATUS.LOADING,
      };
    }

    case GET_SESSION_SUCCESS:
    case CREATE_SESSION_SUCCESS: {
      const { session } = action.payload;
      return {
        ...state,
        status: STATUS.SUCCESS,
        session,
      };
    }

    case GET_SESSION_FAILURE:
    case CREATE_SESSION_FAILURE: {
      return {
        ...state,
        status: STATUS.FAILURE,
      };
    }

    case UPDATE_SESSION_SUCCESS:
    case UPDATE_SESSION_PLAYER_SUCCESS: {
      const { session } = action.payload;
      return {
        ...state,
        session: {
          ...state.session,
          // $FlowFixMe
          ...session,
        },
      };
    }

    case UPDATE_SESSION_PLAYER_FAILURE:
    case UPDATE_SESSION_FAILURE: {
      const { session } = state;
      return {
        ...state,
        session: session
          ? { ...session, players: [...session.players] }
          : null,
      };
    }

    default:
      return state;
  }
}
