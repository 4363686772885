// @flow

import { SET_LOCALE } from 'actions/localeActions';

import type { ActionType } from 'types/actionsType';

export type StateType = {
 locale: 'fr' | 'en' | 'es' | 'de',
};

const initialState: StateType = {
  locale: 'fr',
};

export default function modalState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    default:
      return state;
  }
}
