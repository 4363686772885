// @flow

import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

import PaymentApi from 'api/paymentApi';

import {
  CREATE_STRIPE_SESSION,
  createStripeSessionSuccess,
  createStripeSessionFailure,
  createStripeSessionExpired,
} from 'actions/paymentActions';

import type { CREATE_STRIPE_SESSION_ACTION } from 'actions/paymentActions';

import { selectSessionId } from 'selectors/sessionSelector';

import { stripeApiKey } from 'constants/stripe';

export default function (apiExecutor: ApiExecutorType) {
  const paymentApi = new PaymentApi(apiExecutor);

  function* createStripeSession(action: CREATE_STRIPE_SESSION_ACTION) {
    const sessionId = yield select(selectSessionId);

    try {
      const { payload: { baseUrl, userEmail, cgv } } = action;
      const sessionData = {
        baseUrl, sessionId, userEmail, cgv,
      };
      const { data: { checkoutSessionId }, success } = yield call(paymentApi.createStripeSession, sessionData);

      // eslint-disable-next-line global-require
      const { loadStripe } = require('@stripe/stripe-js');
      const stripe = yield call(loadStripe, stripeApiKey);

      if (success) {
        yield put(createStripeSessionSuccess());

        const { error } = yield call(stripe.redirectToCheckout({
          sessionId: checkoutSessionId,
        }));

        if (error) {
          throw new Error(error);
        }
      } else {
        throw new Error('Error with Stripe payment');
      }
    } catch (error) {
      if (error.response.status === 400) {
        yield put(createStripeSessionExpired());
      } else {
        yield put(createStripeSessionFailure());
      }
    }
  }

  return function* paymentSaga(): Saga<*> {
    yield takeLatest(CREATE_STRIPE_SESSION, createStripeSession);
  };
}
