// @flow

import { connect } from 'react-redux';

import Register from 'components/register/Register';
import { checkOrganizationsFetched, onlyValidInitialization } from 'hoc';
import { register } from 'actions/userActions';
import { selectOrganizations } from 'selectors/organizationSelector';

import type { StateType } from 'types/actionsType';
import type { DispatchProps, StateProps } from 'components/register/Register';

const mapStateToProps = (state: StateType): StateProps => ({
  status: state.userState.status,
  organizations: selectOrganizations(state),
});

const mapDispatchToProps: DispatchProps = {
  register,
};

export default checkOrganizationsFetched(
  onlyValidInitialization(
    connect(mapStateToProps, mapDispatchToProps)(Register),
  ),
);
