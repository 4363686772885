// @flow

export const CREATE_STRIPE_SESSION = 'CREATE_STRIPE_SESSION';
export type CREATE_STRIPE_SESSION_ACTION = {
  type: typeof CREATE_STRIPE_SESSION;
  payload: { baseUrl: string, userEmail: string, cgv: boolean };
}
export const createStripeSession = (baseUrl: string, userEmail: string, cgv: boolean): CREATE_STRIPE_SESSION_ACTION => ({
  type: CREATE_STRIPE_SESSION,
  payload: { baseUrl, userEmail, cgv },
});

export const CREATE_STRIPE_SESSION_SUCCESS = 'CREATE_STRIPE_SESSION_SUCCESS';
export type CREATE_STRIPE_SESSION_SUCCESS_ACTION = {
  type: typeof CREATE_STRIPE_SESSION_SUCCESS;
}
export const createStripeSessionSuccess = (): CREATE_STRIPE_SESSION_SUCCESS_ACTION => ({
  type: CREATE_STRIPE_SESSION_SUCCESS,
});

export const CREATE_STRIPE_SESSION_FAILURE = 'CREATE_STRIPE_SESSION_FAILURE';
export type CREATE_STRIPE_SESSION_FAILURE_ACTION = {
  type: typeof CREATE_STRIPE_SESSION_FAILURE;
}
export const createStripeSessionFailure = (): CREATE_STRIPE_SESSION_FAILURE_ACTION => ({
  type: CREATE_STRIPE_SESSION_FAILURE,
});

export const CREATE_STRIPE_SESSION_EXPIRED = 'CREATE_STRIPE_SESSION_EXPIRED';
export type CREATE_STRIPE_SESSION_EXPIRED_ACTION = {
  type: typeof CREATE_STRIPE_SESSION_EXPIRED;
}
export const createStripeSessionExpired = (): CREATE_STRIPE_SESSION_EXPIRED_ACTION => ({
  type: CREATE_STRIPE_SESSION_EXPIRED,
});

export type Action =
  | CREATE_STRIPE_SESSION_ACTION
  | CREATE_STRIPE_SESSION_SUCCESS_ACTION
  | CREATE_STRIPE_SESSION_EXPIRED_ACTION
  | CREATE_STRIPE_SESSION_FAILURE_ACTION;
