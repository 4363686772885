// @flow

import type { FidelityCardType, FidelityFeedbackType } from 'types/fidelityCardType';

export const ADD_FIDELITY_CARD = 'ADD_FIDELITY_CARD';
export type ADD_FIDELITY_CARD_ACTION = {
  type: typeof ADD_FIDELITY_CARD;
  payload: {
    fidelityCard: FidelityCardType,
  };
}
export const addFidelityCard = (fidelityCard: FidelityCardType): ADD_FIDELITY_CARD_ACTION => ({
  type: ADD_FIDELITY_CARD,
  payload: { fidelityCard },
});

export const ADD_FIDELITY_CARD_SUCCESS = 'ADD_FIDELITY_CARD_SUCCESS';
export type ADD_FIDELITY_CARD_SUCCESS_ACTION = {
  type: typeof ADD_FIDELITY_CARD_SUCCESS;
  payload: {
    message: FidelityFeedbackType;
  };
}
export const addFidelityCardSuccess = (message: FidelityFeedbackType): ADD_FIDELITY_CARD_SUCCESS_ACTION => ({
  type: ADD_FIDELITY_CARD_SUCCESS,
  payload: {
    message,
  },
});

export const ADD_FIDELITY_CARD_FAILURE = 'ADD_FIDELITY_CARD_FAILURE';
export type ADD_FIDELITY_CARD_FAILURE_ACTION = {
  type: typeof ADD_FIDELITY_CARD_FAILURE;
  payload: {
    message: FidelityFeedbackType,
  };
}
export const addFidelityCardFailure = (message: FidelityFeedbackType): ADD_FIDELITY_CARD_FAILURE_ACTION => ({
  type: ADD_FIDELITY_CARD_FAILURE,
  payload: { message },
});

export const DELETE_CARD_NUMBER = 'DELETE_CARD_NUMBER';
export type DELETE_CARD_NUMBER_ACTION = {
  type: typeof DELETE_CARD_NUMBER;
  payload: {
    playerIndex: number,
  };
}
export const deleteCardNumber = (playerIndex: number): DELETE_CARD_NUMBER_ACTION => ({
  type: DELETE_CARD_NUMBER,
  payload: { playerIndex },
});

export type Action =
  | ADD_FIDELITY_CARD_ACTION
  | ADD_FIDELITY_CARD_SUCCESS_ACTION
  | ADD_FIDELITY_CARD_FAILURE_ACTION
  | DELETE_CARD_NUMBER_ACTION;
