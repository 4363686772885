// @flow

import { connect } from 'react-redux';

import { hideSnackBar } from 'actions/snackBarActions';
import SnackBar, {
  type StateProps,
  type DispatchProps,
} from 'components/feedback/SnackBar';

import type { StateType } from 'types/actionsType';

const mapStateToProps = (state: StateType): StateProps => ({
  isOpened: state.snackBarState.isOpened,
  content: state.snackBarState.content,
  duration: state.snackBarState.duration,
});

const mapDispatchToProps: DispatchProps = {
  hideSnackBar,
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBar);
