// @flow

import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';

import Button from 'components/fragments/Button';
import Checkbox from 'components/fragments/Checkbox';
import Loader from 'components/fragments/Loader';
import ToolTip from 'components/fragments/ToolTip';
import Collapsable from 'components/fragments/Collapsable';
import CustomizePlayer from 'components/session/CustomizePlayer';
import GolfIcon from 'assets/GolfIcon';
import HoleIcon from 'assets/HoleIcon';
import TimeIcon from 'assets/TimeIcon';
import ToolTipIcon from 'assets/ToolTipIcon';
import PlayersIcon from 'assets/PlayersIcon';
import UserIcon from 'assets/UserIcon';
import STATUS from 'constants/status';
import { COLORS, CLASSNAME } from 'constants/styles';
import computeStyles from 'utils/styles';
import { useTranslation } from 'components/translationProvider/TranslationProvider';
import { totalPrice } from 'utils/priceCalculator';

import type { StatusType } from 'types/statusType';
import type { ModalDataType } from 'types/modalType';
import type {
  SessionType,
  SessionPlayerType,
} from 'types/sessionType';

import { baseUrl } from 'constants/stripe';

import styles from 'styles/session.module.css';

export type DispatchProps = {
  createStripeSession: Function,
  showModal: (modal: ModalDataType) => any,
  hideModal: Function,
  updateSession: (players: SessionPlayerType[]) => any,
};

export type StateProps = {
  status: StatusType,
  session: SessionType | null,
  paymentStatus: StatusType,
  userEmail: string,
};

type Props = DispatchProps & StateProps;

const Session = ({
  status,
  session,
  createStripeSession,
  showModal,
  hideModal,
  updateSession,
  userEmail,
  paymentStatus,
}: Props) => {
  const [players, setPlayers] = useState<SessionPlayerType[]>([]);
  const [optCgv, setOptCgv] = useState<boolean>(false);

  const [modalExpired, setModalExpired] = useState<boolean>(false);
  const [{ t, locale }] = useTranslation();


  const updatePlayerIndexFactory = useCallback((index: number) => (player: SessionPlayerType) => {
    const { players: prevPlayers = [] } = session || {};

    prevPlayers[index] = { ...prevPlayers[index], ...player };

    updateSession(prevPlayers);
    hideModal();
  }, [session, updateSession, hideModal]);

  const customizePlayerFactory = useCallback((
    player: SessionPlayerType,
    index: number,
    connectedUserCard?: string,
  ) => () => (
    index !== 0 && showModal({
      title: t('session.create.customize_player'),
      children: <CustomizePlayer
        connectedUserCard={connectedUserCard}
        playerIndex={index}
        firstName={player.firstName}
        lastName={player.lastName}
        hideModal={hideModal}
        onComplete={updatePlayerIndexFactory(index)} />,
    })
  ), [updatePlayerIndexFactory, showModal, hideModal, t]);

  const setCgv = useCallback((value) => setOptCgv(value), []);

  const isValidPurchase = useMemo(() => !!optCgv, [optCgv]);

  const purchase = useCallback(() => (
    isValidPurchase
    && createStripeSession(baseUrl, userEmail, optCgv)
  ), [createStripeSession, userEmail, isValidPurchase, optCgv]);

  useEffect(() => {
    if (session) {
      setPlayers(session.players);
    }
  }, [session]);

  useEffect(() => {
    if (paymentStatus === 'expired') {
      setModalExpired(true);
    }
  }, [paymentStatus]);


  const handleClose = () => {
    setModalExpired(false);
  };

  const renderCgv = useMemo(() => (
    <div>
      <Checkbox
        name={'cgv'}
        label={t('session.create.cgv_label')}
        innerHtml
        onChange={setCgv} />
    </div>
  ), [t, locale]);

  if (!session || status === STATUS.LOADING) {
    return (
      <Loader center />
    );
  }

  session.dateTime.locale(locale);
  session.expiredAt.locale(locale);

  const connectedUserCard = players[0]?.cardNumber || '';

  return (
    <div className={styles.main}>
      <div className={styles.left_container}>
        <div className={styles.left_content}>
          <Collapsable previewHeight={80}>
            <>
              <div className={styles.section_container}>
                <GolfIcon />
                <div className={styles.information_container}>
                  <p className={styles.title}>
                    {session.organizationName}
                  </p>
                  <p className={styles.sub_title}>
                    {session.courseName}
                  </p>
                </div>
                <b className={computeStyles(styles.price, CLASSNAME.onlyMobile)}>
                  {`${totalPrice(session.partnerFee, players)} €`}
                </b>
              </div>
              <div className={styles.section_container}>
                <HoleIcon fill={COLORS.primary} />
                <p className={styles.section_label}>
                  {t('session.create.hole_label', { count: session.holeNb })}
                </p>
              </div>
              <div className={styles.section_container}>
                <TimeIcon fill={COLORS.primary} />
                <p className={styles.section_label}>
                  {t('session.create.time_label', {
                    date: session.dateTime.format('DD MMMM YYYY'),
                    hour: session.dateTime.format('HH[h]mm'),
                  })}
                </p>
              </div>

              <div className={styles.section_container}>
                <PlayersIcon fill={COLORS.primary} />
                <p className={styles.section_label}>
                  {t('session.create.player_label', { count: players.length })}
                </p>
              </div>
              <div className={computeStyles(styles.section_container, CLASSNAME.onlyDesktop)}>
                <p className={styles.sub_title}>
                  {t('session.create.total_label')}
                </p>
                <b className={computeStyles(styles.price)}>
                  {`${totalPrice(session.partnerFee, players)} €`}
                </b>
              </div>
            </>
          </Collapsable>
        </div>
        <div className={styles.limit}>
          <span className={styles.limitMsg}>{t('session.create.limit', { date: session.expiredAt.format('HH:mm') })}</span>
          <ToolTip name={'no_refund'} text={t('session.create.no_refund_info')}>
            <p>
              {t('session.create.no_refund')}
              <ToolTipIcon />
            </p>
          </ToolTip>
        </div>
      </div>
      <div className={styles.right_content}>
        <p className={styles.title_right}>
          {t('session.create.title')}
        </p>
        <p className={styles.info_right}>{t('session.create.info')}</p>
        {players.map((player, index) => (
          <div
            onClick={customizePlayerFactory(player, index, connectedUserCard)}
            // eslint-disable-next-line react/no-array-index-key
            key={`player-${index}`}
            className={styles.player}>
            <div className={styles.player_name_container}>
              <p className={styles.player_name}>{`${player.firstName} ${player.lastName}`}</p>
              {!!player.fidelityProgram && (
                <div className={styles.player_card}>
                  {`${t('session.create.card')} ${String(player.fidelityProgram)}`}
                </div>
              )}
            </div>
            <div className={styles.player_price_container}>
              {index !== 0 && !player.fidelityProgram && (
                <div className={styles.player_customize}>
                  <UserIcon />
                  <p>{t('session.create.customize_player')}</p>
                </div>
              )}
              {typeof player.partnerFee === 'number'
                && !Number.isNaN(player.partnerFee)
                && player.partnerFee !== session.partnerFee
                && (
                  <p className={styles.public_price}>
                    {`${session.partnerFee} €`}
                  </p>
                )}
              <p className={styles.player_price}>
                {`${player.partnerFee || session.partnerFee} €`}
              </p>
            </div>
          </div>
        ))}
        {renderCgv}
        <div className={styles.purchase_button}>
          <Button
            status={paymentStatus}
            isDisabled={paymentStatus === STATUS.LOADING || !isValidPurchase}
            label={t('session.create.checkout_label')}
            buttonType={'primary'}
            type={'submit'}
            onClick={purchase} />
        </div>
      </div>
      {modalExpired
        && (
          <div className={styles.paiementFail}>
            <div className={styles.paiementFail_box}>
              <p className={styles.paiementFail_title}>{t('session.error.session_expired_title')}</p>
              <p className={styles.paiementFail_text}>{t('session.error.session_expired_text')}</p>
              <Button
                label={t('close')}
                buttonType={'primary'}
                type={'button'}
                onClick={handleClose} />
            </div>
          </div>
        )}

    </div>
  );
};

export default React.memo<Props>(Session);
