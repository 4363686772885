// @flow

import React from 'react';

import 'styles/checkIcon.css';

type Props = {
  roundFill?: string;
  checkFill?: string;
}

const CheckIcon = ({ roundFill, checkFill }: Props) => (
  <div className={'customCheck'}>
    <svg  className={'check'}  width={'24'} height={'24'} fill={'none'} xmlns="http://www.w3.org/2000/svg">
      <circle cx={'12'}  cy={'12'} r={'12'} fill={roundFill} />
      <path
        d="M17.5917 8.00834c-.0775-.07811-.1696-.1401-.2712-.18241-.1015-.04231-.2105-.06409-.3205-.06409-.11 0-.2189.02178-.3204.06409-.1016.04231-.1938.1043-.2712.18241L10.2 14.225l-2.60829-2.6167c-.08044-.0777-.17539-.1388-.27944-.1798-.10404-.041-.21514-.0611-.32696-.0591-.11182.0019-.22216.0258-.32473.0704-.10256.0446-.19534.1089-.27304.1894-.0777.0804-.13879.1754-.1798.2794-.041.1041-.06111.2152-.05918.327.00194.1118.02588.2221.07046.3247.04458.1026.10892.1953.18935.273l3.2 3.2c.07747.0781.16964.1401.27119.1824.10155.0424.21044.0641.32044.0641.11 0 .219-.0217.3205-.0641.1016-.0423.1937-.1043.2712-.1824l6.8-6.79996c.0846-.07804.1521-.17275.1983-.27816.0461-.10542.07-.21926.07-.33434 0-.11509-.0239-.22892-.07-.33434-.0462-.10542-.1137-.20013-.1983-.27816z"
        fill={checkFill} />
    </svg>
  </div> 
);

CheckIcon.defaultProps = {
  roundFill: '#D8EFCD',
  checkFill: '#007031',
};

export default CheckIcon;
