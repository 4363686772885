// @flow

import type { OrganizationChoice } from 'types/organizationType';

export const LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS';
export type LIST_ORGANIZATIONS_ACTION = {
  type: typeof LIST_ORGANIZATIONS,
};
export const listOrganizations = (): LIST_ORGANIZATIONS_ACTION => ({
  type: LIST_ORGANIZATIONS,
});

export const LIST_ORGANIZATIONS_SUCCESS = 'LIST_ORGANIZATIONS_SUCCESS';
export type LIST_ORGANIZATIONS_SUCCESS_ACTION = {
  type: typeof LIST_ORGANIZATIONS_SUCCESS,
  payload: { organizations: OrganizationChoice[] },
};
export const listOrganizationsSuccess = (organizations: OrganizationChoice[]): LIST_ORGANIZATIONS_SUCCESS_ACTION => ({
  type: LIST_ORGANIZATIONS_SUCCESS,
  payload: { organizations },
});

export const LIST_ORGANIZATIONS_FAILURE = 'LIST_ORGANIZATIONS_FAILURE';
export type LIST_ORGANIZATIONS_FAILURE_ACTION = {
  type: typeof LIST_ORGANIZATIONS_FAILURE,
};
export const listOrganizationsFailure = (): LIST_ORGANIZATIONS_FAILURE_ACTION => ({
  type: LIST_ORGANIZATIONS_FAILURE,
});

export type Action =
  | LIST_ORGANIZATIONS_ACTION
  | LIST_ORGANIZATIONS_SUCCESS_ACTION
  | LIST_ORGANIZATIONS_FAILURE_ACTION;
