// @flow

import React from 'react';

import styles from 'styles/forgetPassword.module.css';
import { CONSUMER_CONFIG } from 'constants/config';
import { useTranslation } from 'components/translationProvider/TranslationProvider';

const ForgetPassword = () => {
  const [{ t }] = useTranslation();

  return (
    <p className={styles.content}>
      {t('login.forget_password.content', { clientName: CONSUMER_CONFIG.name })}
    </p>
  );
};

export default React.memo<{}>(ForgetPassword);
