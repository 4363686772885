// @flow

import React from 'react';

type Props = {
  fill: string;
  width: string;
};

const ToolTipIcon = ({ fill, width }: Props) => (
  <svg width={width} height={'18'} viewBox={'0 0 18 18'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      // eslint-disable-next-line max-len
      d={'M8.16669 13.1667h1.66666V8.16667H8.16669v5.00003zM9.00002.666672c-4.6 0-8.333333 3.733338-8.333333 8.333338 0 4.59999 3.733333 8.33329 8.333333 8.33329 4.59998 0 8.33338-3.7333 8.33338-8.33329 0-4.6-3.7334-8.333338-8.33338-8.333338zm0 15.000028c-3.675 0-6.66667-2.9917-6.66667-6.66669 0-3.675 2.99167-6.66667 6.66667-6.66667 3.67498 0 6.66668 2.99167 6.66668 6.66667 0 3.67499-2.9917 6.66669-6.66668 6.66669zm-.83333-9.16669h1.66666V4.83334H8.16669v1.66667z'}
      fill={fill} />
  </svg>
);

ToolTipIcon.defaultProps = {
  fill: '#CBCCCD',
  width: '18',
};

export default ToolTipIcon;
