// @flow

import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';
import * as moment from 'moment';

import SessionApi from 'api/sessionApi';
import { history } from 'browser';
import {
  CREATE_SESSION,
  createSessionSuccess,
  createSessionFailure,
  UPDATE_SESSION,
  UPDATE_SESSION_PLAYER,
  updateSessionSuccess,
  updateSessionFailure,
  updateSessionPlayerSuccess,
  updateSessionPlayerFailure,
  GET_SESSION,
  getSessionSuccess,
  getSessionFailure,
} from 'actions/sessionActions';
import { logout } from 'actions/userActions';
import { selectSession, selectToken } from 'selectors/sessionSelector';
import { selectUserInformation } from 'selectors/userSelector';
import { showSnackBar } from 'actions/snackBarActions';
import { HOME_PATH } from 'constants/routes';

export default function (apiExecutor: ApiExecutorType) {
  const sessionApi = new SessionApi(apiExecutor);

  function* onCreateSession() {
    const token = yield select(selectToken);
    const { firstName, lastName } = yield select(selectUserInformation);

    if (!firstName || !lastName) {
      // @xxx we ensure that required informations are present
      yield put(logout());
      return;
    }

    try {
      const { success, data } = yield call(sessionApi.createSession, {
        token,
        firstName,
        lastName,
      });
      if (!success) {
        throw new Error(
          data.message || 'fail to create session for an unknown reason',
        );
      }

      const {
        id,
        players,
        partnerName,
        partnerBookingId,
        checkoutSessionId,
        expiredAt,
        teeTime: {
          organizationName,
          organizationId,
          courseName,
          dateTime,
          availableGreenFeesNb,
          publicFee,
          partnerFee,
          holeNb,
        },
      } = data;
      yield put(
        createSessionSuccess({
          id,
          partnerName,
          players,
          partnerBookingId,
          organizationName,
          organizationId,
          courseName,
          dateTime: moment(dateTime),
          availableGreenFeesNb,
          publicFee,
          partnerFee,
          holeNb,
          stripeId: checkoutSessionId,
          expiredAt: moment(expiredAt),
        }),
      );
    } catch {
      yield put(showSnackBar('session.create.error_message'));
      yield put(createSessionFailure());
      yield call(history.replace, HOME_PATH);
    }
  }

  function* onUpdateSessionPlayer(action) {
    const { players: sessionUpdates } = action.payload;
    const session = yield select(selectSession);

    if (!session) {
      yield put(updateSessionPlayerFailure());
    }

    try {
      const playersUpdated = session.players.map((player, index) => ({
        ...player,
        firstName: sessionUpdates[index].firstName,
        lastName: sessionUpdates[index].lastName,
      }));

      const { success, data } = yield call(
        sessionApi.updateSession,
        session.id,
        { players: playersUpdated },
      );

      if (!success) {
        throw new Error(
          data.message || 'fail to update session for an unknown reason',
        );
      }

      const { players } = data;

      yield put(
        updateSessionPlayerSuccess({
          players,
        }),
      );
    } catch {
      yield put(showSnackBar('session.update.error_message'));
      yield put(updateSessionPlayerFailure());
    }
  }

  function* onUpdateSession(action) {
    const { session: sessionUpdates } = action.payload;
    const session = yield select(selectSession);

    if (!session) {
      yield put(updateSessionFailure());
    }

    try {
      const { success, data } = yield call(
        sessionApi.updateSession,
        session.id,
        sessionUpdates,
      );

      if (!success) {
        throw new Error(
          data.message || 'fail to update session for an unknown reason',
        );
      }

      const { players } = data;

      yield put(
        updateSessionSuccess({
          players,
        }),
      );
    } catch {
      yield put(showSnackBar('session.update.error_message'));
      yield put(updateSessionFailure());
    }
  }

  function* onGetSession(action) {
    const { sessionId } = action.payload;

    try {
      const { success, data } = yield call(
        sessionApi.getSession,
        sessionId,
      );
      if (!success) {
        throw new Error(
          data.message || 'fail to get session for an unknown reason',
        );
      }

      const {
        id,
        players,
        partnerBookingId,
        partnerName,
        checkoutSessionId,
        expiredAt,
        teeTime: {
          organizationName,
          organizationId,
          courseName,
          dateTime,
          availableGreenFeesNb,
          publicFee,
          partnerFee,
          holeNb,
        },
      } = data;
      yield put(
        getSessionSuccess({
          id,
          partnerName,
          players,
          partnerBookingId,
          organizationName,
          organizationId,
          courseName,
          dateTime: moment(dateTime),
          availableGreenFeesNb,
          publicFee,
          partnerFee,
          holeNb,
          stripeId: checkoutSessionId,
          expiredAt: moment(expiredAt),
        }),
      );
    } catch {
      yield put(showSnackBar('session.get.error_message'));
      yield put(getSessionFailure());
    }
  }

  return function* sessionSaga(): Saga<*> {
    yield takeLatest(CREATE_SESSION, onCreateSession);
    yield takeLatest(UPDATE_SESSION, onUpdateSession);
    yield takeLatest(UPDATE_SESSION_PLAYER, onUpdateSessionPlayer);
    yield takeLatest(GET_SESSION, onGetSession);
  };
}
