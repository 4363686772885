// @flow

import React from 'react';

const GolfIcon = () => (
  <svg width={'52'} height={'41'} fill={'none'} xmlns="http://www.w3.org/2000/svg">
    <path 
      d="M41.1388 23.7747c1.0969.2563 2.1353.5403 3.1015.8501 5.4255 1.7395 8.3796 4.2037 7.3662 7.2441-.7668 2.3004-2.1614 2.8093-5.8223 3.0282-3.1338.1874-4.5511.3994-6.5179 1.1861-1.0047.4019-1.9101.8188-2.9405 1.3394-.3064.1549-2.0007 1.0359-2.4826 1.274-3.0756 1.5197-5.6768 2.1367-10.1769 2.1367C9.98001 40.8333.333008 37.1305.333008 31.5c0-3.6871 5.162302-6.6241 12.833292-8.1604V1.74553C13.1663.779736 13.9609 0 14.9163 0c.6673 0 1.2511.380242 1.546.930603.1969-.035288.4071-.020154.6137.057297l14 5.25c1.0093.37851 1.0093 1.80626 0 2.18477l-14 5.25003c-.1366.0512-.2748.0751-.4097.0753v9.0119c2.8929-.3864 6.0409-.5932 9.3334-.5932 2.8047 0 5.5923.1565 8.2399.4507.3304-1.5896 1.7391-2.7841 3.4267-2.7841 1.933 0 3.5 1.567 3.5 3.5 0 .1495-.0093.2968-.0275.4414zM40.8 24.8939c-.5737 1.1497-1.7614 1.9394-3.1337 1.9394-1.7794 0-3.2488-1.328-3.4709-3.047-2.6081-.2933-5.3788-.453-8.1957-.453-3.3048 0-6.4567.2181-9.3334.6135v4.0621c.1922-.0059.3868-.0089.5834-.0089 3.5408 0 6.4166.9586 6.4166 2.9167 0 1.958-2.8758 2.9166-6.4166 2.9166s-6.4167-.9586-6.4167-2.9166c0-1.1007.9087-1.8855 2.3333-2.3618V24.542C6.16616 25.9796 1.49967 28.5582 1.49967 31.5c0 4.5103 8.63573 8.1667 22.16663 8.1667 8.1667 0 9.3334-2.3334 15.1667-4.6667 5.8333-2.3333 10.5 0 11.6667-3.5.9011-2.7034-3.2747-5.1165-9.6997-6.6061zm-24.1337 4.2835v3.4786c.1916.007.3862.0107.5834.0107 2.8995 0 5.25-.7835 5.25-1.75s-2.3505-1.75-5.25-1.75c-.1972 0-.3918.0036-.5834.0106v.0001zm-3.5 2.8393v-2.2001c-.7296.3006-1.1666.6833-1.1666 1.1001 0 .4167.437.7994 1.1666 1.1zm3.5-29.93642V12.5803l14-5.25002-14-5.25zM15.4997 32.6667V1.7403c0-.30131-.2612-.57363-.5834-.57363-.3127 0-.5833.25916-.5833.57886V32.4333l1.1667.2334zm22.1666-7c1.2887 0 2.3334-1.0447 2.3334-2.3334 0-1.2886-1.0447-2.3333-2.3334-2.3333-1.2886 0-2.3333 1.0447-2.3333 2.3333 0 1.2887 1.0447 2.3334 2.3333 2.3334z" 
      fill="#007031" />
  </svg>
);

export default GolfIcon;