// @flow

const STATUS = {
  DEFAULT: 'default',
  LOADING: 'loading',
  FAILURE: 'failure',
  SUCCESS: 'success',
  EXPIRED: 'expired',
};

export default STATUS;
