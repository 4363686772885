// @flow
import React from 'react';

import Breadcrumbs from 'components/navigation/Breadcrumbs';
import LangSwitcher from 'components/navigation/LangSwitcher';
import Logo from 'components/app/Logo';

import styles from 'styles/app.module.css';

const RoutesHeader = () => (
  <div className={styles.header}>
    <Logo />
    <Breadcrumbs />
    <LangSwitcher />
  </div>
);

export default RoutesHeader;
