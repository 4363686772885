// @flow

export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';
export type SHOW_SNACK_BAR_ACTION = {
  type: typeof SHOW_SNACK_BAR,
  payload: { content: string, duration: ?number },
};
export const showSnackBar = (content: string, duration: ?number): SHOW_SNACK_BAR_ACTION => ({
  type: SHOW_SNACK_BAR,
  payload: { content, duration },
});

export const HIDE_SNACK_BAR = 'HIDE_SNACK_BAR';
export type HIDE_SNACK_BAR_ACTION = {
  type: typeof HIDE_SNACK_BAR,
};
export const hideSnackBar = (): HIDE_SNACK_BAR_ACTION => ({
  type: HIDE_SNACK_BAR,
});

export type Action = SHOW_SNACK_BAR_ACTION | HIDE_SNACK_BAR_ACTION;
