// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import reducers from 'reducers';
import sagas from 'sagas';
import { PERSIST_KEY } from 'constants/config';

const userFilter = createFilter(
  'userState',
  ['firstName', 'lastName', 'token', 'email'],
);

const paymentFilter = createFilter(
  'paymentState',
  ['stripeSessionId'],
);
const localeFilter = createFilter(
  'localeState',
  ['locale'],
);

const persistConfig = {
  key: PERSIST_KEY,
  storage,
  whitelist: ['userState', 'paymentState', 'localeState'],
  transforms: [userFilter, paymentFilter, localeFilter],
  stateReconciler: autoMergeLevel2,
};

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-undef
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
