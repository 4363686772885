// @flow

export const SET_LOCALE = 'SET_LOCALE';
export type SET_LOCALE_ACTION = {
  type: typeof SET_LOCALE,
  payload: {
    locale: 'fr' | 'en' | 'es' | 'de',
  },
};
export const setLocale = (locale: 'fr' | 'en' | 'es' | 'de'): SET_LOCALE_ACTION => ({
  type: SET_LOCALE,
  payload: {
    locale,
  },
});

export type Action = SET_LOCALE_ACTION;
