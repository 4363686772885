// @flow

import { connect } from 'react-redux';

import { getSession, getSessionFailure } from 'actions/sessionActions';
import PaymentConfirmation, { type StateProps, type DispatchProps } from 'components/session/PaymentConfirmation';
import { onlyLogged } from 'hoc';

import type { StateType } from 'types/actionsType';

const mapDispatchToProps: DispatchProps = {
  fetchSession: getSession,
  failFetchSession: getSessionFailure,
};

const mapStateToProps = (state: StateType): StateProps => ({
  status: state.sessionState.status,
  session: state.sessionState.session,
  userEmail: state.userState.email,
});

export default onlyLogged(
  connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation),
);
