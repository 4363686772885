// @flow

import type { ModalDataType } from 'types/modalType';

export const SHOW_MODAL = 'SHOW_MODAL';
export type SHOW_MODAL_ACTION = {
  type: typeof SHOW_MODAL,
  payload: ModalDataType,
};
export const showModal = (modalData: ModalDataType): SHOW_MODAL_ACTION => ({
  type: SHOW_MODAL,
  payload: modalData,
});

export const HIDE_MODAL = 'HIDE_MODAL';
export type HIDE_MODAL_ACTION = {
  type: typeof HIDE_MODAL,
};
export const hideModal = (): HIDE_MODAL_ACTION => ({
  type: HIDE_MODAL,
});

export type Action = SHOW_MODAL_ACTION | HIDE_MODAL_ACTION;
