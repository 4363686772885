// @flow

import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';

import { history } from 'browser';

import Button from 'components/fragments/Button';
import Loader from 'components/fragments/Loader';
import GenericError from 'components/fragments/GenericError';
import Collapsable from 'components/fragments/Collapsable';
import { useTranslation } from 'components/translationProvider/TranslationProvider';
import { useQuery } from 'hooks';
import GolfIcon from 'assets/GolfIcon';
import HoleIcon from 'assets/HoleIcon';
import TimeIcon from 'assets/TimeIcon';
import PlayersIcon from 'assets/PlayersIcon';
import STATUS from 'constants/status';
import { RETURN_URL_LCG } from 'constants/config';
import { COLORS, CLASSNAME } from 'constants/styles';
import computeStyles from 'utils/styles';
import styles from 'styles/paymentConfirmation.module.css';
import { totalPrice } from 'utils/priceCalculator';

import type { StatusType } from 'types/statusType';
import type {
  SessionType,
  SessionPlayerType,
} from 'types/sessionType';

import { HOME_PATH, SELECT_PLAYERS_PATH } from 'constants/routes';

import { isValidationUrl, isCancelUrl } from 'utils/stripeValidator';
import { redirectToBackUrl } from 'utils/links';

export type DispatchProps = {
  fetchSession: (id: string) => any,
  failFetchSession: () => any,
};

export type StateProps = {
  status: StatusType,
  session: SessionType | null,
  userEmail: string,
};

type Props = StateProps & DispatchProps;

const PaymentConfirmation = ({
  status,
  session,
  userEmail,
  fetchSession,
  failFetchSession,
}: Props) => {
  const query = useQuery();
  const sessionId = useRef(query.get('id'));
  const [players, setPlayers] = useState<SessionPlayerType[]>([]);
  const [{ t, locale }] = useTranslation();

  const isPartnerLCG = useMemo(() => session && session.partnerName === 'leclubgolf', [session]);

  const handleBack = useCallback(() => {
    if (isPartnerLCG) {
      return window.open(RETURN_URL_LCG, '_self', '', true);
    }

    return session && redirectToBackUrl(session.partnerBookingId);
  }, [session, isPartnerLCG]);

  const backButtonLabel = useMemo(() => (
    isPartnerLCG
      ? t('payment_confirmation.back_button_lcg')
      : t('payment_confirmation.back_button')
  ), [isPartnerLCG, t]);

  useEffect(() => {
    if (session) {
      const {
        stripeId,
        players: sessionPlayers,
        partnerFee: fees,
        organizationName,
        organizationId,
      } = session;

      const total = totalPrice(fees, sessionPlayers);

      if (window && window.dataLayer) {
        window.dataLayer.push({
          ecommerce: {
            purchase: {
              actionField: {
                id: stripeId,
                revenue: total,
                tax: '​​',
                shipping: '',
              },
              products: sessionPlayers.map((player) => ({
                name: `Green Fee ${organizationName}`,
                id: organizationId,
                price: `${player.partnerFee || session.partnerFee}`.replace('.', ','),
                quantity: '1',
              })),
            },
          },
        });
      }
    }
  }, [session]);

  useEffect(() => {
    const { location: { pathname } } = history;

    if (!isValidationUrl(pathname)) {
      if (isCancelUrl(pathname)) {
        history.replace(SELECT_PLAYERS_PATH);
      } else {
        history.replace(HOME_PATH);
      }
    }

    if (session) {
      setPlayers(session.players);
    }
  }, [session]);

  useEffect(() => {
    if (sessionId.current) {
      fetchSession(sessionId.current);
    } else {
      failFetchSession();
    }
  }, [fetchSession, failFetchSession]);

  const priceTotal = useMemo(() => {
    if (session) {
      return totalPrice(session.partnerFee, players);
    }

    return 0;
  }, [session, players]);

  if (status === STATUS.FAILURE) {
    return <GenericError msg={t('payment_confirmation.error_message')} />;
  }

  if (!session || status === STATUS.LOADING) {
    return <Loader center />;
  }

  session.dateTime.locale(locale);

  return (
    <div className={styles.main}>
      <div className={styles.left_content}>
        <Collapsable previewHeight={80}>
          <>
            <div className={styles.section_container}>
              <GolfIcon />
              <div className={styles.information_container}>
                <p className={styles.title}>
                  {session.organizationName}
                </p>
                <p className={styles.sub_title}>
                  {session.courseName}
                </p>
              </div>
              <b className={computeStyles(styles.price, CLASSNAME.onlyMobile)}>
                {`${totalPrice(session.partnerFee, players)} €`}
              </b>
            </div>
            <div className={styles.section_container}>
              <HoleIcon fill={COLORS.primary} />
              <p className={styles.section_label}>
                {t('session.create.hole_label', { count: session.holeNb })}
              </p>
            </div>
            <div className={styles.section_container}>
              <TimeIcon fill={COLORS.primary} />
              <p className={styles.section_label}>
                {t('session.create.time_label', {
                  date: session.dateTime.format('DD MMMM YYYY'),
                  hour: session.dateTime.format('HH[h]mm'),
                })}
              </p>
            </div>

            <div className={styles.section_container}>
              <PlayersIcon fill={COLORS.primary} />
              <p className={styles.section_label}>
                {t('session.create.player_label', { count: players.length })}
              </p>
            </div>
            <div className={computeStyles(styles.section_container, CLASSNAME.onlyDesktop)}>
              <p className={styles.sub_title}>
                {t('session.create.total_label')}
              </p>
              <b className={computeStyles(styles.price)}>
                {`${totalPrice(session.partnerFee, players)} €`}
              </b>
            </div>
          </>
        </Collapsable>
      </div>
      <div className={styles.right_content}>
        <p className={styles.title_right}>
          {t('payment_confirmation.title')}
        </p>
        <p className={styles.explanation}>
          {t('payment_confirmation.explanation', { email: userEmail })}
        </p>
        <div className={styles.fees_container}>
          <p className={styles.fees_title}>
            {t('session.create.fees_title', { fees: Math.trunc(priceTotal) })}
          </p>
          <p className={styles.fees_description}>
            {t('session.create.fees_description')}
          </p>
        </div>
        <p className={styles.subtitle}>
          {t('payment_confirmation.subtitle')}
        </p>
        {players.map((player, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`player-${index}`} className={styles.player}>
            <p className={styles.player_name}>{`${player.firstName} ${player.lastName}`}</p>
            <div className={styles.player_price_container}>
              <p className={styles.player_price}>
                {`${player.partnerFee || session.partnerFee} €`}
              </p>
            </div>
          </div>
        ))}
        <div className={styles.total}>
          <p className={styles.total_name}>
            {t('payment_confirmation.total_name')}
          </p>
          <div className={styles.total_price_container}>
            <p className={styles.total_price}>
              {`${priceTotal} €`}
            </p>
          </div>
        </div>
        <div className={styles.back_button}>
          <Button
            status={STATUS.DEFAULT}
            isDisabled={false}
            label={backButtonLabel}
            buttonType={'primary'}
            type={'submit'}
            onClick={handleBack} />
        </div>
      </div>
    </div>
  );
};

export default React.memo<Props>(PaymentConfirmation);
