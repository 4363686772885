// @flow

import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import CloseIcon from 'assets/CloseIcon';
import { COLORS } from 'constants/styles';
import computeStyles from 'utils/styles';
import styles from 'styles/modal.module.css';

import { type ModalDataType } from 'types/modalType';

const DEFAULT_STYLE = { display: 'none' };

const [bodyNode] = document.getElementsByTagName('body');
const modalNode = document.createElement('div');

export type StateProps = {
  isOpened: boolean,
};

export type DispatchProps = {
  hideModal: Function,
};

type Props = ModalDataType & StateProps & DispatchProps;

const Modal = ({
  isOpened, title, children, hideModal,
}: Props) => {
  useEffect(() => {
    bodyNode.appendChild(modalNode);
    return () => {
      bodyNode.removeChild(modalNode);
    };
  }, []);

  return createPortal(
    <>
      <div
        onClick={hideModal}
        className={computeStyles(styles.container, isOpened ? styles.overlay_visible : styles.overlay_hidden)} />
      <CSSTransition
        in={isOpened}
        classNames={{
          enterActive: computeStyles(styles.container, styles.modal, styles.modal_visible),
          enterDone: computeStyles(styles.container, styles.modal, styles.modal_visible),
          exitActive: computeStyles(styles.container, styles.modal, styles.modal_hidden_active),
          exitDone: computeStyles(styles.container, styles.modal, styles.modal_hidden),
        }}
        timeout={300}>
        <div style={DEFAULT_STYLE}>
          <button onClick={hideModal} className={styles.close_button}>
            <CloseIcon fill={COLORS.primary} />
          </button>
          <h1 className={styles.title}>{title}</h1>
          {children}
        </div>
      </CSSTransition>
    </>,
    modalNode,
  );
};

export default Modal;
