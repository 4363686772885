// @flow

import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import styles from 'styles/login.module.css';
import Input from 'components/fragments/Input';
import Button from 'components/fragments/Button';
import ForgetPassword from 'components/login/ForgetPassword';
import { useTranslation } from 'components/translationProvider/TranslationProvider';
import CheckIcon from 'assets/checkIcon';
import { isValidEmail } from 'utils/validators';
import STATUS from 'constants/status';
import { CONSUMER_CONFIG } from 'constants/config';
import { REGISTER_PATH } from 'constants/routes';

import type { StatusType } from 'types/statusType';
import type { ModalDataType } from 'types/modalType';

export type DispatchProps = {
  auth: Function,
  showModal: (modal: ModalDataType) => any,
};

export type StateProps = {
  status: StatusType,
};

type Props = DispatchProps & StateProps;

const Login = ({ auth, status, showModal }: Props) => {
  const [email, setEmail]: [string, Function] = useState('');
  const [password, setPassword]: [string, Function] = useState('');
  const [{ t }] = useTranslation();

  const handleEmail = (value: string) => {
    setEmail(value);
  };

  const handlePassword = (value: string) => {
    setPassword(value);
  };

  const authenticate = (e) => {
    e.preventDefault();
    auth({ email, password });
  };

  const createAccount = () => null; // @TODO connect to create account

  const isLogEnabled = () => password !== '' && isValidEmail(email); // @TODO ASK FOR PASSWORD MINIMUM INPUT

  const validateEmail = useCallback(() => {
    const valid = isValidEmail(email);
    return {
      valid,
      error: valid ? null : t('input.email.error_message'),
    };
  }, [email, t]);

  const validatePassword = useCallback(() => {
    const valid = password.length > 0;
    return {
      valid,
      error: valid ? null : t('input.password.error_message'),
    };
  }, [password, t]);

  const showForgetPassword = useCallback(
    () => showModal({
      title: t('login.forget_password.title'),
      children: <ForgetPassword />,
    }),
    [showModal, t],
  );

  return (
    <div className={styles.main}>
      <div className={styles.leftContent}>
        <div className={styles.mainTitleLeft}>
          <p>
            {t('login.left.title', {
              clientName: CONSUMER_CONFIG.name,
            })}
          </p>
        </div>
        <div className={styles.explanationLeft}>
          <p className={styles.titleLeft}>
            {t('login.left.explanation.title', {
              clientName: CONSUMER_CONFIG.name,
            })}
          </p>
          <p className={styles.textLeft}>{t('login.left.explanation.text')}</p>
        </div>
        <form className={styles.loginForm} onSubmit={authenticate}>
          <div className={styles.input}>
            <Input
              label={t('input.email.label')}
              placeholder={t('input.email.placeholder')}
              type={'email'}
              name={'email'}
              initialValue={email}
              onChange={handleEmail}
              validate={validateEmail()}
              autoComplete={'email'} />
          </div>
          <div className={styles.inputPassword}>
            <Input
              label={t('input.password.label')}
              placeholder={t('input.password.placeholder')}
              type={'password'}
              name={'password'}
              initialValue={password}
              onChange={handlePassword}
              validate={validatePassword()}
              autoComplete={'current-password'} />
          </div>
          <div className={styles.connectionContainer}>
            <div className={styles.connectButtonContainer}>
              <Button
                status={status}
                label={t('login.left.button')}
                type={'submit'}
                onClick={authenticate}
                isDisabled={status === STATUS.LOADING || !isLogEnabled()} />
            </div>
            <div onClick={showForgetPassword} className={styles.forgotPassword}>
              <span>{t('login.left.forgot_password')}</span>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.rightContent}>
        <div className={styles.titleRight}>
          <span>
            {t('login.right.title', { clientName: CONSUMER_CONFIG.name })}
          </span>
        </div>
        <div className={styles.explanationRigth}>
          <div className={styles.subtitle}>
            <span>
              {t('login.right.explanation.title', {
                clientName: CONSUMER_CONFIG.name,
              })}
            </span>
          </div>
          <div className={styles.list}>
            <CheckIcon />
            <span className={styles.listText}>
              {t('login.right.check_list.1')}
            </span>
          </div>
          <div className={styles.list}>
            <CheckIcon />
            <span className={styles.listText}>
              {t('login.right.check_list.2')}
            </span>
          </div>
          <div className={styles.list}>
            <CheckIcon />
            <span className={styles.listText}>
              {t('login.right.check_list.3')}
            </span>
          </div>
        </div>
        <div className={styles.subscribeButton}>
          <Link to={REGISTER_PATH}>
            <Button
              label={t('login.right.button')}
              buttonType={'secondary'}
              onClick={createAccount} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
