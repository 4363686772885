// @flow

import { takeLatest, put, call } from 'redux-saga/effects';

import type { Saga } from 'redux-saga';
import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

import {
  LIST_ORGANIZATIONS,
  listOrganizationsSuccess,
  listOrganizationsFailure,
} from 'actions/organizationActions';
import { showSnackBar } from 'actions/snackBarActions';

import OrganizationApi from 'api/organizationApi';

export default function (apiExecutor: ApiExecutorType) {
  const organizationApi = new OrganizationApi(apiExecutor);

  function* list() {
    try {
      const { data: organizations } = yield call(organizationApi.list);

      yield put(
        listOrganizationsSuccess(organizations),
      );
    } catch {
      yield put(showSnackBar('list_organizations.error_message', 5000));
      yield put(listOrganizationsFailure());
    }
  }

  return function* organizationSaga(): Saga<*> {
    yield takeLatest(LIST_ORGANIZATIONS, list);
  };
}
