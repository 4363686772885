// @flow

import React from 'react';

type Props = {
  fill?: string,
};

const CloseIcon = ({ fill }: Props) => (
  <svg
    width={'16'}
    height={'16'}
    viewBox={'0 0 16 16'}
    fill={'none'}
    xmlns={'http://www.w3.org/2000/svg'}>
    <path
      d={
        // eslint-disable-next-line max-len
        'M15.3734 2.04001L13.96 0.626678L8.00004 6.58668L2.04004 0.626678L0.626709 2.04001L6.58671 8.00001L0.626709 13.96L2.04004 15.3733L8.00004 9.41334L13.96 15.3733L15.3734 13.96L9.41338 8.00001L15.3734 2.04001Z'
      }
      fill={fill} />
  </svg>
);

CloseIcon.defaultProps = {
  fill: '#C4492C',
};

export default CloseIcon;
