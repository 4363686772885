// @flow

import type { StateType } from 'types/actionsType';

export const selectToken = (state: StateType) => state.sessionState.token;

export const selectSession = (state: StateType) => state.sessionState.session;

export const selectSessionId = (state: StateType) => (
  state.sessionState.session ? state.sessionState.session.id : null
);
