// @flow

/* eslint-disable */
const envfile = process.env.NODE_ENV === 'development'
  ? '.env'
  : `.env.${process.env.NODE_ENV || 'production'}`;

require('dotenv').config({ path: envfile });

import React from 'react';
import ReactDOM from 'react-dom';

import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';

import './index.css';
import 'styles/main.css';

import App from 'components/app/AppProvider';
import * as serviceWorker from './serviceWorker';
/* eslint-enable */

const rootNode = document.getElementById('root');
if (!rootNode) {
  throw new Error(
    "unfound root node 'root', verify your 'index.html' to ensure that you've implemented one",
  );
}

ReactDOM.render(<App />, rootNode);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
