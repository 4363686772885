// @flow

import uiColors from 'assets/config/ui-colors.json';
import 'styles/main.css';

export const COLORS = uiColors;

export const CLASSNAME = {
  onlyMobile: 'only_mobile',
  onlyDesktop: 'only_desktop',
};
