// @flow

import React from 'react';

type Props = {
  fill: string,
};

const RoundedArrowIcon = ({ fill }: Props) => (
  <svg width={'12'} height={'8'} fill={'none'} xmlns={'http://www.w3.org/2000/svg'}>
    <path
      // eslint-disable-next-line max-len
      d={'M5.28995 6.83l-4.24-4.24c-.09373-.09296-.168124-.20356-.218893-.32542C.780288 2.14272.75415 2.01202.75415 1.88c0-.13201.026138-.26271.076907-.38457.050769-.12186.125163-.23246.218893-.32543.18736-.186247.44081-.290789.705-.290789.26418 0 .51764.104542.705.290789l3.54 3.54 3.54-3.54c.18736-.186247.44081-.290789.70495-.290789.2642 0 .5177.104542.705.290789.0927.09344.1661.20426.2158.3261.0498.12184.075.2523.0742.3839.0008.13161-.0244.26207-.0742.38391-.0497.12184-.1231.23265-.2158.32609L6.70995 6.83c-.09297.09373-.20357.16813-.32543.2189-.12185.05076-.25256.0769-.38457.0769-.13201 0-.26272-.02614-.38458-.0769-.12186-.05077-.23246-.12517-.32542-.2189z'}
      fill={fill} />
  </svg>
);

RoundedArrowIcon.defaultProps = {
  fill: '#fff',
};

export default RoundedArrowIcon;