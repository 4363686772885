// @flow

import {
  ADD_FIDELITY_CARD,
  ADD_FIDELITY_CARD_SUCCESS,
  ADD_FIDELITY_CARD_FAILURE,
} from 'actions/fidelityCardActions';

import type { ActionType } from 'types/actionsType';
import type { StatusType } from 'types/statusType';
import type { FidelityFeedbackType } from 'types/fidelityCardType';

import STATUS from 'constants/status';

export type StateType = {
  status: StatusType,
  message: FidelityFeedbackType | null,
};

const initialState: StateType = {
  status: STATUS.DEFAULT,
  message: null,
};

export default function fidelityCardState(
  state: StateType = initialState,
  action: ActionType,
): StateType {
  switch (action.type) {
    case ADD_FIDELITY_CARD: {
      return {
        ...state,
        status: STATUS.LOADING,
        message: initialState.message,
      };
    }
    case ADD_FIDELITY_CARD_SUCCESS: {
      const { payload: { message } } = action;

      return {
        ...state,
        status: STATUS.SUCCESS,
        message,
      };
    }
    case ADD_FIDELITY_CARD_FAILURE: {
      const { payload: { message } } = action;

      return {
        ...state,
        status: STATUS.FAILURE,
        message,
      };
    }
    default:
      return state;
  }
}
