// @flow
import React from 'react';

import logo from 'assets/images/logo.png';
import styles from 'styles/app.module.css';

const Logo = () => (
  <div className={styles.logo}>
    <img alt={''} src={logo} />
  </div>
);

export default React.memo<{}>(Logo);
