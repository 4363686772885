// @flow

import i18n from 'i18n-js';

import fr from 'translations/fr.json';
import en from 'translations/en.json';
import es from 'translations/es.json';
import de from 'translations/de.json';

const locale = 'fr';

i18n.locale = locale;
i18n.fallbacks = true;
i18n.translations = {
  fr,
  en,
  es,
  de,
};
i18n.missingTranslation = () => {
  console.warn('Missing translation'); // eslint-disable-line
  return '';
};

const { t }: { t: (path: string, options?: any) => string } = i18n;
export { i18n };

export default t.bind(i18n);
