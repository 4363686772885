// @flow

import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';
import type { SessionDataType, UpdateSessionDataType } from 'types/sessionType';

function sessionApi(executor: ApiExecutorType) {
  function createSession(body: SessionDataType) {
    return executor.post('/session', body);
  }

  function updateSession(id: string, body: UpdateSessionDataType) {
    return executor.patch(`/session/${id}`, body);
  }

  function getSession(id: string) {
    return executor.get(`/session/${id}`);
  }

  return {
    createSession,
    updateSession,
    getSession,
  };
}

export default sessionApi;
