// @flow

import { connect } from 'react-redux';

import Login from 'components/login/Login';
import { auth } from 'actions/userActions';
import { showModal } from 'actions/modalActions';
import { checkOrganizationsFetched, onlyValidInitialization } from 'hoc';
import { selectOrganizations } from 'selectors/organizationSelector';

import type { StateType } from 'types/actionsType';
import type { DispatchProps, StateProps } from 'components/login/Login';

const mapStateToProps = (state: StateType): StateProps => ({
  status: state.userState.status,
  isLogged: state.userState.isLogged,
  organizations: selectOrganizations(state),
});

const mapDispatchToProps: DispatchProps = {
  auth,
  showModal,
};

export default checkOrganizationsFetched(
  onlyValidInitialization(
    connect(mapStateToProps, mapDispatchToProps)(Login),
  ),
);
