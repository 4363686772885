// @flow

import _ from 'lodash';
import { takeLatest, put, call } from 'redux-saga/effects';

import type { Saga } from 'redux-saga';
import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

import { history } from 'browser';
import {
  REGISTER,
  registerSuccess,
  registerFailure,
} from 'actions/userActions';
import { showSnackBar } from 'actions/snackBarActions';
import { SELECT_PLAYERS_PATH } from 'constants/routes';

import UserApi from 'api/userApi';

export default function (apiExecutor: ApiExecutorType) {
  const userApi = new UserApi(apiExecutor);

  function* register(action) {
    try {
      const { payload } = action;
      const {
        data: {
          partnerId, partnerName, token, id,
        },
      } = yield call(
        userApi.registering,
        _.omitBy(payload, (value) => !value),
      );

      yield put(
        registerSuccess(
          {
            partnerId,
            partnerName,
            token,
            id,
          },
          payload.firstName,
          payload.lastName,
        ),
      );
      yield call(history.replace, SELECT_PLAYERS_PATH);
      yield call(apiExecutor.setSessionToken, token);
    } catch {
      yield put(showSnackBar('register.error_message'));
      yield put(registerFailure());
    }
  }

  return function* registerSaga(): Saga<*> {
    yield takeLatest(REGISTER, register);
  };
}
