// @flow

import React, { useState, useCallback, useMemo } from 'react';

import styles from 'styles/checkBox.module.css';

type Props = {
  initialValue: boolean;
  name: string;
  label: string;
  onChange: Function;
  innerHtml?: boolean;
};

const Checkbox = ({
  name,
  label,
  onChange: onChangeProps,
  initialValue,
  innerHtml,
}: Props) => {
  const [value, setValue] = useState<boolean>(initialValue);

  const onChange = useCallback((event: SyntheticEvent<HTMLInputElement>) => {
    const { currentTarget: { checked: inputValue } } = event;
    setValue(inputValue);
    onChangeProps(inputValue, name);
  }, [setValue, onChangeProps, name]);

  const renderLabel = useMemo(() => {
    if (innerHtml) {
      return <div className={styles.label} dangerouslySetInnerHTML={{ __html: label }} />;
    }

    if (label) {
      return <label className={styles.label} htmlFor={name}>{label}</label>;
    }

    return null;
  }, [innerHtml, label]);

  return (
    <div className={styles.main}>
      <input
        type={'checkbox'}
        name={name}
        onChange={onChange}
        value={String(value)}
        checked={value} />
      <span className={styles.checkbox} />
      {renderLabel}
    </div>
  );
};

Checkbox.defaultProps = {
  onChange: () => undefined,
  initialValue: false,
};

export default Checkbox;
