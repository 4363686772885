// @flow

import {
  takeLatest, put, call, select,
} from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist';

import type { Saga } from 'redux-saga';
import type { ApiExecutorType } from '@dotmind/utils/dist/react/ApiExecutor';

import UserApi from 'api/userApi';
import { history } from 'browser';
import {
  AUTH,
  authSuccess,
  authFailure,
  AUTH_FROM_CACHE,
  authFromCache,
  authFailureFromCache,
  authSuccessFromCache,
  LOGOUT,
} from 'actions/userActions';
import { showSnackBar } from 'actions/snackBarActions';
import { setLocale } from 'actions/localeActions';
import { selectToken } from 'selectors/userSelector';
import { NOT_REGISTERED } from 'constants/organization';
import { URL_USER_PARAM, URL_USER_EMAIL_PARAM, URL_LOCALE_PARAM } from 'constants/config';
import { SELECT_PLAYERS_PATH, HOME_PATH } from 'constants/routes';

export default function (apiExecutor: ApiExecutorType) {
  const userApi = new UserApi(apiExecutor);

  function* auth(action) {
    try {
      const {
        payload,
        payload: { email },
      } = action;
      const {
        data: {
          token,
          user: { firstName, lastName },
        },
      } = yield call(userApi.authenticate, payload);

      yield put(
        authSuccess({
          token,
          email,
          firstName,
          lastName,
        }),
      );
      yield call(history.replace, SELECT_PLAYERS_PATH);
      yield call(apiExecutor.setSessionToken, token);
    } catch (error) {
      if (
        error.response
        && error.response.data
        && error.response.data.data
        && error.response.data.data.message === NOT_REGISTERED
      ) {
        yield put(showSnackBar('input.organization.bad_organization', 5000));
      } else {
        yield put(showSnackBar('login.error_message'));
      }
      yield put(authFailure());
    }
  }

  function* onAuthFromCache(action) {
    const { location: { pathname } } = history;
    const { payload: { token: userToken, email } } = action;
    /**
     * @XXX
     * Use url token first & fallback with local cache
     */
    const token = yield userToken || select(selectToken);

    yield call(apiExecutor.setSessionToken, token);
    try {
      const {
        data: { token: newToken, userInfo: { firstName, lastName } },
      } = yield call(userApi.getMe);

      yield call(apiExecutor.setSessionToken, newToken);
      yield put(authSuccessFromCache(newToken, email, firstName, lastName));

      if (pathname === HOME_PATH) {
        yield call(history.replace, SELECT_PLAYERS_PATH);
      }
    } catch {
      yield put(authFailureFromCache());
    }
  }

  function* rehydrate(action) {
    const { location: { search } } = history;
    const params = new URLSearchParams(search);
    const token = params.get(URL_USER_PARAM);
    const email = params.get(URL_USER_EMAIL_PARAM);

    // $FlowFixMe
    const locale: 'fr' | 'en' | 'de' | 'es' = params.get(URL_LOCALE_PARAM);

    if (locale) {
      yield put(setLocale(locale));
    }

    if (!token && (action && action.payload && !action.payload.userState)) {
      return;
    }

    yield put(authFromCache(token, email));
  }

  function* onLogout() {
    yield call(history.replace, HOME_PATH);
  }

  return function* authSaga(): Saga<*> {
    yield takeLatest(AUTH, auth);
    yield takeLatest(AUTH_FROM_CACHE, onAuthFromCache);
    yield takeLatest(LOGOUT, onLogout);
    yield takeLatest(REHYDRATE, rehydrate);
  };
}
