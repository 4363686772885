// @flow

import React, { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { useTranslation } from 'components/translationProvider/TranslationProvider';
import WarningIcon from 'assets/WarningIcon';
import { CLASSNAME } from 'constants/styles';
import computeStyles from 'utils/styles';

import styles from 'styles/snackBar.module.css';

const DEFAULT_STYLE = { display: 'none' };
const SNACK_DURATION = 2500; // ms

const [bodyNode] = document.getElementsByTagName('body');
const snackBarNode = document.createElement('div');

export type StateProps = {
  isOpened: boolean,
  content: string,
  duration: ?number,
};

export type DispatchProps = {
  hideSnackBar: Function,
};

type Props = StateProps & DispatchProps;

const SnackBar = ({
  content,
  duration,
  hideSnackBar,
  isOpened,
}: Props) => {
  const timeoutRef = useRef(null);
  const [{ t }] = useTranslation();

  const clearTimeoutRef = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (isOpened) {
      clearTimeoutRef();
      setTimeout(hideSnackBar, duration || SNACK_DURATION);
    }
    return () => clearTimeoutRef();
  }, [isOpened, clearTimeoutRef, duration, hideSnackBar]);

  useEffect(() => {
    bodyNode.appendChild(snackBarNode);
    return () => {
      bodyNode.removeChild(snackBarNode);
    };
  }, []);

  return createPortal(
    <CSSTransition
      in={isOpened}
      classNames={{
        enterActive: computeStyles(styles.container, styles.snack_bar, styles.snack_bar_visible),
        enterDone: computeStyles(styles.container, styles.snack_bar, styles.snack_bar_visible),
        exitActive: computeStyles(styles.container, styles.snack_bar, styles.snack_bar_hidden_active),
        exitDone: computeStyles(styles.container, styles.snack_bar, styles.snack_bar_hidden),
      }}
      timeout={300}>
      <div style={DEFAULT_STYLE}>
        <div className={computeStyles(styles.icon, CLASSNAME.onlyDesktop)}>
          <WarningIcon />
        </div>
        <p className={styles.content}>{content && t(content)}</p>
      </div>
    </CSSTransition>,
    snackBarNode,
  );
};

export default SnackBar;
